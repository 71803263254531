import Card from "../../card/Card";

export default function SessionErrorsTable ({ sessions }) {

  function renderTableContent() {
    let rows = [];
    sessions.sort((a, b) => {
      let dateA = new Date(a.day);
      let dateB = new Date(b.day);
      return dateB.getTime() - dateA.getTime();
    });
    sessions.forEach(s => {
      if (s.error) {
        let error = '';
        for (let step in s.steps) {
          if (s.steps[step].status && s.steps[step].status.indexOf('Error') >= 0) {
            error = s.steps[step].status;
          }
        }
        let r = <tr key={`error-${s.session}`}>
          <td>{s.session}</td>
          <td>{s.day}</td>
          <td>{s.techId}</td>
          <td>{s.serialNumber}</td>
          <td>{translateError(error)}</td>
        </tr>;
        rows.push(r);
      }
    });
    return rows;
  }

  function translateError(error) {
    let errors = {
      noClliFound: 'No unit address found in Triad nor DPI.',
      loginFailed: 'Unsupported OLT model or SSH login error.',
      unsupportedOlt: 'Unsupported OLT model.',
      sshPrompt: 'Error while finding the expected characters or line in the OLT ssh connection.'
    }

    let errorKey = error;
    if (error.includes('No CLLI')) {
      errorKey = 'noClliFound';
    } else if (error.includes('Login')) {
      errorKey = 'loginFailed';
    } else if (error.includes('vendor')) {
      errorKey = 'unsupportedOlt';
    } else if (error.includes('prompt')) {
      errorKey = 'sshPrompt';
    }
    
    return errors[errorKey] || errorKey;
  }

  return <Card className='dashboard-errors'>
    <header>Errors</header>
    <section>
      <table className="table table-dark table-striped table-hover session-errors">
        <thead>
          <tr>
            <td>Session</td>
            <td>Day</td>
            <td>Tech</td>
            <td>ONT SN</td>
            <td>Error</td>
          </tr>
        </thead>
        <tbody>{ renderTableContent() }</tbody>
      </table>
    </section>
  </Card>;
};
