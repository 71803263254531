import { useEffect, useState } from 'react';
import Card from '../card/Card';
import { putSmithSchedule } from '../../utils/psi-smith-apis';
import { Col, Form, Row, Button, Alert } from 'react-bootstrap';
import speedTestServers from '../../utils/speedTestServers';

export default function SmithAddSchedules ({}) {
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [ontSerialNumber, setOntSerialNumber] = useState('');
	const [startTime, setStartTime] = useState('');
	const [server, setServer] = useState('speedtest.frontier.com');
	const [latencyUrls, setLatencyUrls] = useState('');
	const [ulBandwidth, setUlBandwidth] = useState('');
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	//This regex requires full http URL (e.g. http://www.google.com)
	const urlRegExp = /(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?/ig;
	
	useEffect(() => {
		
	}, [startTime]);

	const putSchedule = async (event) => {
		setLoading(true);
		setError(false);
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;

		console.log(`is form valid: ${form.checkValidity()}`);
		if (!form.checkValidity()) {
			setValidated(false);
			return;
		}
		else {
			setValidated(true);
		}
		


		const timestamp = new Date(startTime);
		const urlLatency = [...latencyUrls.toLowerCase().matchAll(urlRegExp)].map(m => m[0]);
		/*
		Check if there are valid URL's. Commented out to allow for empty URL's while testing
		if(!urlLatency || urlLatency.length == 0)
			{
				console.log(`No valid latency URL's.`);
				setError(`No valid latency URL's found. Please ensure they are of the form http://www.google.com.`);
				return;
			}
		*/
		const s = {
			ontSerialNumber: ontSerialNumber,
			startTime: parseInt(timestamp / 1000),
			urlSpeedTestServer: server,
			urlLatency: urlLatency,
			ulBandwidth: parseFloat(ulBandwidth)
		};

		console.log(s);
		let results;
		try
			{
				results = await putSmithSchedule(ontSerialNumber, s);
				console.log(`Results" ${results}`);
				setLoading(false);
				setSuccess(true);
			}
		catch (err)
			{
				setLoading(false);
				setError(`There was an error saving the schedule: ${err}`);
			}
	}

	function validateStartTime() {
		console.log('validatng start time.');
		const timestamp = new Date(startTime);
		const isValid = timestamp > Date.now();
		const field = document.getElementById('startTime-input');
		if (!field) {
			return;
		}

		if (isValid) {
			field.setCustomValidity('');
		}
		else if (startTime) {
			field.setCustomValidity('The start time is in the past.');
		}
		
		return isValid;
	}

	const form = <Form onSubmit={putSchedule} validated={validated}>
	<Row className="mb-5">
		<Col md={3}>
			<Form.Group>
				<Form.Label>ONT Serial Number</Form.Label>
				<Form.Control type="text" value={ontSerialNumber} onChange={e => {setOntSerialNumber(e.target.value.trim())}} required />
			</Form.Group>
		</Col>
		<Col md={4}>
			<Form.Group>
				<Form.Label>Start time</Form.Label>
				<Form.Control
					type="datetime-local"
					value={startTime}
					onChange={e => {setStartTime(e.target.value)}}
					placeholder='Your local time'
					required
					isValid={validateStartTime()}
					id='startTime-input'
				/>
			</Form.Group>
		</Col>
		<Col md={2}>
			<Form.Group>
				<Form.Label>Bandwidth</Form.Label>
				<Form.Control type="number" value={ulBandwidth} onChange={e => {setUlBandwidth(e.target.value)}} placeholder='In Gbps' required/>
			</Form.Group>
		</Col>
		<Col md={3}>
			<Form.Group>
				<Form.Label>Speed Test Server</Form.Label>
				<Form.Select value={server} required onChange={e => {
					console.log("e.target.value", e.target.value);
					setServer(e.target.value);
				}}>
					{speedTestServers.map(s => {
						return <option value={s.host}>{s.name}</option>
					})}
				</Form.Select>
			</Form.Group>
		</Col>
	</Row>
	<Row className="mb-1">
		<Form.Label>Latency URLs (should be of the form http://www.google.com)</Form.Label>
		<p>Enter the URLs separated by comma (,) or a space. Non valid URLs will be ignored.</p>
		<Form.Control as="textarea" rows={5} value={latencyUrls} onChange={e => {setLatencyUrls(e.target.value)}} />
		<p>Detected Valid URLs: {[...latencyUrls.toLowerCase().matchAll(urlRegExp)].toString()}</p>
	</Row>
	<Row>
		<Col md={2}><Button variant="primary" type='submit'>Submit</Button></Col>
	</Row>
</Form>;

	let displayLoading;
	let displaySuccess;
	let displayError;
	let displayForm;

  if (loading) {
		displayLoading = <h3>Loading...</h3>
	}
	else if (error) {
    displayError = <Alert variant="danger">
      <Alert.Heading>Error</Alert.Heading>
      <p>{error}</p>
    </Alert>;
		displayForm = form;
  }
	else if (success) {
		displaySuccess = <Alert variant="success">
      <Alert.Heading>Success</Alert.Heading>
      <p>Successfully saved schedule.</p>
    </Alert>;
		displayForm = form;
	}
	else {
		displayForm = form;
	}
	
	return (
		<Card className={'top-10'}>
			<header>Agent Smith Schedules</header>
			<section>
				{displayLoading}
				{displayError}
				{displaySuccess}
				{displayForm}
			</section>
		</Card>
	)
}

