import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const socketUrl = 'wss://caj26pg3s0.execute-api.us-west-2.amazonaws.com/dev';
const socket = new WebSocket(socketUrl);

socket.onerror = (error) => {
  console.log('WS connection error', error);
}

export default function TopicMonitorWS() {
  const [messages, setMessages] = useState([]);
  const [topic, setTopic] = useState('');
  const [listening, setListening] = useState(false);

  const messagesList = messages.map(m => <pre className="mqtt-message" key={m.key}>{JSON.stringify(m.body, null, 2)}</pre>);

  function handleFormSubmission(event) {
    event.preventDefault();

    const onMessage = (event) => {
      console.log(`Got message. Listening: ${listening}`, event);

      if (!listening) {
        return;
      }

      let message = {
        key: `message-${Date.now()}`,
        body: event.toString()
      }
      setMessages(prev => [message, ...prev]);
    }

    if (listening) {
      setListening(false);
      socket.send(JSON.stringify({type: 'unsubscribe', topic: topic}));
      return;
    }

    socket.send(JSON.stringify({type: 'subscribe', topic: topic}));

    socket.onmessage = onMessage;

    setListening(true);
  }

  const clearMessages = () => {
    setMessages([]);
  }

  let button;

  if (listening) {
    button = <Button variant="warning" type="cancel">Disconnect</Button>
  } else {
    button = <Button variant="primary" type="submit">Connect</Button>
  }

  return (
    <div className="mqtt-form">
      <h3>Topic Monitor</h3>
      <Form onSubmit={handleFormSubmission}>
        <Row className="mb-3">
          <Form.Group className="mb-3" as={Col} md="3">
            <Form.Label>Topic</Form.Label>
            <Form.Control type="text" placeholder="Enter Topic" value={topic} onChange={e => setTopic(e.target.value)} required />
          </Form.Group>
        </Row>
        <Row>
          <Col md="9">{button}</Col>
          <Col md="3"><Button variant="secondary" type="button" onClick={clearMessages}>Clear messages</Button></Col>
        </Row>
      </Form>
      <div className="mqtt-messages-list">
        {messagesList}
      </div>
    </div>
  )
}
