import { useState } from 'react';

const tokenKey = 'token';

const getDate = () => {
  return new Date().toISOString().split('T')[0];
}

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    let userToken = JSON.parse(tokenString);

    if (!userToken || userToken.date !== getDate()) {
      if (userToken) {
        localStorage.removeItem(tokenKey);
      }
      userToken = null;
    }

    return userToken?.token;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if (!userToken) {
      localStorage.removeItem(tokenKey);
      setToken(userToken);
      return;
    } else {
      const newToken = {
        token: userToken,
        date: getDate()
      }
      localStorage.setItem(tokenKey, JSON.stringify(newToken));
      setToken(userToken);
    }
  };

  const isTokenValid = () => {
    const token = getToken();
    return !!token;
  }

  return {
    setToken: saveToken,
    token,
    isTokenValid
  }
}
