import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Card from "../card/Card";
import { getOntDiagnostics, getOntLastSnapshot } from "../../utils/psi-ont-api";
import { psiSearchSubscriber } from "../../utils/psi-subscriber-api";

const fields = {
  ontSn: 'serialNumber',
  usi: 'subscriberId',
  ticketId: 'ticketId'
}

export default function SparkSearch ({ setOnt, setSubscriber, setError, setLoading }) {
  const [field , setField] = useState(fields.ontSn);
  const [value, setValue] = useState('');
  
  async function handleSubmission(e) {
    e.preventDefault();
    console.log(`Searching for: ${field} = ${value}`);
    setError(false);
    try {
      setLoading(true);
      let subscriberDetails = await psiSearchSubscriber(field, value);
      console.log(subscriberDetails);
      setSubscriber(subscriberDetails);
      setLoading(false);
    }
    catch (e) {
      console.log(e);
      if (!e.response?.status && e.code === 'ERR_NETWORK') {
        setError(`403: The request was not authorized. Please contact fybrlabs.`);
      }
      else if (field === fields.ontSn && e.response.status === 404) {
        try {
          let ontDetails = await getOntLastSnapshot(value);
          setOnt(ontDetails);
        }
        catch (e) {
          setError(`404: Device not found with serial number ${value}`);
        }
      }
      else if (field === fields.usi) {
        setError(`404: Subscriber not found with ID: ${value}`);
      }
      setLoading(false);
    }
  }

  return <Card>
    <header>Search</header>
    <section>
      <Form onSubmit={handleSubmission}>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Search by</Form.Label>
              <Form.Select onChange={e => setField(e.target.value)} defaultValue={fields.ontSn}>
                <option value={fields.ontSn}>ONT Serial Number</option>
                <option value={fields.usi}>Subscriber ID</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Value</Form.Label>
              <Form.Control type="text" value={value} onChange={e => {setValue(e.target.value)}}/>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Button type="submit" className="mt-4">Search</Button>
          </Col>
        </Row>
      </Form>
    </section>
  </Card>;
}