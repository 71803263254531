import { Container } from "react-bootstrap";
import Card from "../card/Card";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function SubscriberDetails ({ subscriberDetails }) {

  let address = 'No data';

  if (subscriberDetails.serviceAddress) {
    address = subscriberDetails.serviceAddress.addressLine1;
    if (subscriberDetails.serviceAddress.addressLine2) {
      address += ' ' + subscriberDetails.serviceAddress.addressLine2;
    }
    address += `, ${subscriberDetails.serviceAddress.city}, ${subscriberDetails.serviceAddress.state} ${subscriberDetails.serviceAddress.zipCode}`;
  }

  return <Card className="spark-details">
      <header>Subscriber Details</header>
      <section>
        <Row>
          <Col>
            <Row>
              <Col><p>Name</p></Col>
              <Col>{subscriberDetails.name}</Col>
            </Row>
            <Row>
              <Col><p>Address</p></Col>
              <Col><p>{address}</p></Col>
            </Row>
            <Row>
              <Col><p>Telephone</p></Col>
              <Col><p>{subscriberDetails.billingTelephoneNumber}</p></Col>
            </Row>
            <Row>
              <Col><p>Location</p></Col>
              <Col><p>{subscriberDetails.locationId}</p></Col>
            </Row>
            <Row>
              <Col><p>subscriber ID</p></Col>
              <Col><p>{subscriberDetails.subscriberId}</p></Col>
            </Row>
          </Col>
        </Row>
      </section>
    </Card>
}