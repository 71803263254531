import axios from "axios";

export const getOltSessions = async () => {
	let r = await axios.get(`https://sparqc.ftr.com/endpoints/status.php?onlyConns`);
	let connections = r.data?.connections || [];
	return connections;
}

const vqcServerStatuses = {
	'NORM' : 'Normal',
	'DELAY' : 'Busy',
	'TIMEOUT' : 'Traffic',
	'NO_RESP' : 'Check Your VPN Connection'
}

const checkInThreshold = 120;

export const getVQCStatus = async () => {
	console.log('getVQCStatus');

	let vqcStatus = {};
	var response = {};
	try {
		let url = 'https://vqc.ftr.com/endpoints/status.php';
		response = await axios.get(url);
	} catch(e) {
		console.log('getVQCLastCheckin ', e.message);

	}		
	finally {
		if (response.status === 200) {
			let vqcSrvrs = response.data?.servers || [];
			vqcSrvrs.map((serverItem) => {
				let lastCheckin = serverItem.lastCheckin * 1000;
				let difference = new Date().getTime() - lastCheckin;
				console.log('lastCheckin VQC ', difference);
				
				if (difference > checkInThreshold) {
					vqcStatus[serverItem.name] = vqcServerStatuses.DELAY;
				}
				
				vqcStatus[serverItem.name] = vqcServerStatuses.NORM;
			});
			
		}
		else {
			vqcStatus['Error'] = vqcServerStatuses.NO_RESP;
		}
		
		return vqcStatus;
	}
}