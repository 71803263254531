import { Row, Container, Button, Col } from "react-bootstrap";
import Alert from "./alert";
import { useEffect, useState } from "react";
import axios from "axios";
import { getAllAlerts, deleteAlert as cmsDeleteAlert } from "../../../utils/cms-apis";
import { getAccessToken } from "../../../utils/auth";

export default function AlertsList ({ }) {
  const [alerts, setAlerts] = useState([]);

  function addNewAlert() {
    let nextKey = 0;
    alerts.forEach(alert => {
      nextKey = nextKey - alert.key > 0 ? nextKey + 1 : alert.key + 1;
    });
    let newAlert = {
      key: nextKey,
      name: '',
      message: '',
      condition: '',
      displayTypes: {},
      recommendations: []
    }
    setAlerts([...alerts, newAlert ]);
  }

  function deleteAlert(key) {
    let newAlerts = [];
    alerts.forEach(async alert => {
      if (alert.key !== key) {
        newAlerts.push(alert);
      } else {
        const token = await getAccessToken();
        cmsDeleteAlert(alert, token);
      }
    });
    setAlerts(newAlerts);
  }

  async function getAlerts() {
    const token = await getAccessToken();
    let alerts = await getAllAlerts(token);
    alerts.forEach((alert, i) => {
      let dTypes = {};
      alert.displayTypes.forEach(type => {
        dTypes[type] = true;
      });
      alert.displayTypes = dTypes;
      alert.key = i;
      alert.recommendations.forEach((rec, j) => {
        rec.key = `${i}_${j}`;
      });
    });
    setAlerts(alerts);
  }

  useEffect(() => {
    getAlerts();
  }, [])

  return <Container className="cms-alert-list">
    <h2>Alerts</h2>
    <Row className="mb-5">
      <Col style={{textAlign: 'right'}} ><Button variant="info" onClick={addNewAlert}>Add New Alert</Button></Col>
    </Row>
    {alerts.map(alert => {
      return <Alert name={alert.name} message={alert.message} displayTypes={alert.displayTypes}
      condition={alert.condition} recommendations={alert.recommendations} key={alert.key}
      keyProp={alert.key} deleteAlert={deleteAlert} />
    })}
  </Container>;
}