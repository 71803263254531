import axios from "axios";
import { getAccessToken } from "./auth";
const sessionId = 'psiconsole';
const techId = 'psiconsole';

export const prefetchOntDiagnostics = async (sn) => {
  const token = await getAccessToken()
  let r = await axios.get(`https://ont.api.fybrlabs.frontier.com/Prod/v2/triggerprefetch/${sn}?inclAddress=false&uuid=${sessionId}&techId=${techId}`, {headers: { 'auth-token': token }});
  return r.data;
}

export const getOntDiagnostics = async (sn) => {
  const token = await getAccessToken()
  let r = await axios.get(`https://ont.api.fybrlabs.frontier.com/Prod/v2/getontdiags/${sn}?uuid=${sessionId}&techId=${techId}`, {headers: { 'auth-token': token }});
  return r.data;
}

export const getOntLastSnapshot = async (sn) => {
  const token = await getAccessToken()
  let r = await axios.get(`https://ont.api.fybrlabs.frontier.com/Prod/v2/${sn}?uuid=${sessionId}&techId=${techId}`, {headers: { 'auth-token': token }});
  return r.data;
}

export const startFibertest = async (sn, tests = false) =>
  {
    let url = `https://0hp14oeq2d.execute-api.us-east-2.amazonaws.com/Prod/v2/${sn}/tests/fiberTest`;  //Fybrlabs
    //let url = `https://wmqfaeh5qd.execute-api.us-west-2.amazonaws.com/Prod/v2/${sn}/tests/fiberTest`;  //Video
    if(tests && tests !== '')
      {url += '?type=' + tests;}

    const token = await getAccessToken();
    let r = await axios.put(url, {}, {headers: { 'auth-token': token }});
    return r;
  }

export const getFibertest = async (sn) =>
  {
    let url = `https://0hp14oeq2d.execute-api.us-east-2.amazonaws.com/Prod/v2/${sn}/tests/fiberTest`;  //Fybrlabs
    //let url = `https://wmqfaeh5qd.execute-api.us-west-2.amazonaws.com/Prod/v2/${sn}/tests/fiberTest`;  //Video
    
    const token = await getAccessToken();
    let r = await axios.get(url, {headers: { 'auth-token': token }});
    return r;
  }

export const getFibertestHistory = async (dates) =>
    {
      console.log('Getting history...');
      console.log(dates);
      let startTime = Math.floor(dates.startDate.getTime()/1000);
      let endTime = Math.ceil(dates.endDate.getTime()/1000);
      let url = `https://0hp14oeq2d.execute-api.us-east-2.amazonaws.com/Prod/v2/fibertest/history?startTime=${startTime}&endTime=${endTime}`;  //Fybrlabs
      //let url = `https://wmqfaeh5qd.execute-api.us-west-2.amazonaws.com/Prod/v2/fibertest/history?startTime=${startTime}&endTime=${endTime}`;  //Video

      console.log(url);
      
      const token = await getAccessToken();
      let r = await axios.get(url, {headers: { 'auth-token': token }});
      console.log(r);
      return r;
    }

export const formatDates  = (start = new Date(), end = new Date()) =>
  {
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
      return [start, end];
  }
