import { exportToCsv } from "../../utils/export";

const heatmapFields = {
  oltRxSignalLevel: {field: 'oltRxSignalLevel', label: 'OLT RX', units: 'dBm'},
  ontRxSignalLevel: {field: 'ontRxSignalLevel', label: 'ONT RX', units: 'dBm'},
  ontDistance: {field: 'ontDistance', label: 'Distance', units: 'KM'},
}

const getDataPoints = (items, xAxis, yAxis) => {
  xAxis = xAxis || heatmapFields.distance.field;
  yAxis = yAxis || heatmapFields.oltRxSignalLevel.field;

  let counter = countOccurrences(items, xAxis, yAxis);
  let oltPoints = generatePoints(counter.counter, xAxis, yAxis);

  return {
    oltPoints
  };
}

const countOccurrences = (items, xAxis, yAxis) => {
  let counter = {};
  let total = 0;

  items.forEach((item) => {
    if (!isNaN(item[yAxis])) {
      const yValue = parseFloat(parseFloat(item[yAxis]).toFixed(1));

      if (!isNaN(item[xAxis]) && item[xAxis] !== '') {
        const xValue = parseFloat(item[xAxis]);

        if (!counter[yValue]) {
          counter[yValue] = {};
        }
        if (!counter[yValue][xValue]) {
          counter[yValue][xValue] = 0;
        }
        counter[yValue][xValue]++;
        total++;
      }
    }
  });

  return {counter, total};
}

const generatePoints = (counter, xAxis, yAxis) => {
  let max = 0;
  let points = [];

  Object.keys(counter).forEach((yValue) => {
    Object.keys(counter[yValue]).forEach((xValue) => {
      const quantity = counter[yValue][xValue];
      if (quantity) {
        max = Math.max(max, quantity);
        let point = {
          x: parseFloat(xValue) * 100,// * 6, //d * 10 * scale * 4,
          y: parseFloat(yValue) * -100,// * 6, //r * -10 * scale * 4,
          value: quantity
        };
        point[xAxis] = xValue;
        point[yAxis] = yValue;
        point.xLabel = heatmapFields[xAxis].label;
        point.xValue = xValue + heatmapFields[xAxis].units;
        point.yLabel = heatmapFields[yAxis].label;
        point.yValue = yValue + heatmapFields[yAxis].units;
        points.push(point);
      }
    });
  });

  return {dataPoints: points, max};
}

const exportHeatmapRawData = (data, fileName) => {
  console.log('export data: ', data);
  let rows = [
    ['Date', 'ONT Serial Number', 'OLT RX', 'ONT RX', 'Distance', 'Optical Temperature']
  ];
  for (let i in data) {
    data[i].forEach((item) => {
      let row = [
        item.date,
        item.ontSerialNumber,
        item.oltRxSignalLevel,
        item.ontRxSignalLevel,
        item.ontDistance,
        item.ontOpticalTemperature
      ];
      rows.push(row);
    });
  }
  exportToCsv(rows, fileName);
}

const getAnomalies = (data) => {
  let onts = {};
  let moved = {};
  let outsideGoldilocks = { ont: {}, olt: {} };
  
  for (let i in data) {
    data[i].forEach((item) => {
      if (!onts[item.ontSerialNumber]) {
        onts[item.ontSerialNumber] = {
          sn: item.ontSerialNumber,
          items: []
        };
      }

      onts[item.ontSerialNumber].items.push({
        rx: item.oltRxSignalLevel,
        date: item.date
      });

      if (!isNaN(item.oltRxSignalLevel)) {
        const rx = parseFloat(item.oltRxSignalLevel);
        let ont = onts[item.ontSerialNumber];
        if (!onts[item.ontSerialNumber].lowest) {
          ont.lowest = rx;
          ont.highest = rx;
          ont.lowestDate = item.date;
          ont.highestDate = item.date;
          ont.diff = 0;
        } else {
          if (ont.lowest > rx) {
            ont.lowestDate = item.date;
            ont.lowest = rx;
          }
          if (ont.highest < rx) {
            ont.highestDate = item.date;
            ont.highest = rx;
          }
          ont.diff = parseFloat(Math.abs(Math.abs(ont.lowest) - Math.abs(ont.highest)).toFixed(2));
          if (ont.diff >= 3) {
            moved[item.ontSerialNumber] = true;
          }
        }

        if (!isValueInGoldilocks(rx)) {
          outsideGoldilocks.olt[item.ontSerialNumber] = {
            sn: item.ontSerialNumber,
            oltRx: rx
          };
        }
      }
    });
  }
  moved = Object.keys(moved).map(sn => onts[sn]);
  moved = moved.sort((a, b) => {
    return b.diff - a.diff;
  })
  console.log('Anomalies', onts, moved);
  return {onts, moved, outsideGoldilocks};
}

const isValueInGoldilocks = (value) => {
  const highest = -13;
  const lowest = -28;
  return value >= lowest && value <= highest;
}

export { getDataPoints, heatmapFields, exportHeatmapRawData, getAnomalies };
