import { useEffect, useState } from 'react';
import Card from '../card/Card';
import { getSmithConfig, setSmithConfig } from '../../utils/psi-smith-apis';
import { Col, Form, Row, Button, Alert } from 'react-bootstrap';

export default function SmithConfig ({}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [latencyUrls, setLatencyUrls] = useState('');
	const [snForcing7g, setSnForcing7g] = useState('');
	let content;
	const urlRegExp = /(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?/ig;
	
	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			setLoading(true);
			let data = await getSmithConfig();
			data.forEach(item => {
				switch (item.name) {
					case 'onts7gig':
						setSnForcing7g(item.value.join('\n'));
						break;
					
					case 'latencyUrls':
						setLatencyUrls(item.value.join('\n').toLowerCase());
						break;
				}
			});
			setLoading(false);
		}
		catch (e) {
			setError(`An error occurred: ${e.toString()}`);
		}
	}

	let displayMessage;

	if (loading) {
		content = <h3>Loading...</h3>;
	}
	else {
		if (error) {
			displayMessage = <Alert variant="danger" dismissible>{error}</Alert>
		}
		else if (success) {
			displayMessage = <Alert variant="success" dismissible>Configuration submitted successfully.</Alert>
		}

		content = <Form onSubmit={submitConfig}>
			<Row className="mb-5">
				<Form.Label>ONTs forcing 7Gbps speed test</Form.Label>
				<p>Enter the ONT serial numbers separated by comma (,), a space or a new line.</p>
				<Form.Control as="textarea" rows={5} value={snForcing7g} onChange={e => {setSnForcing7g(e.target.value)}} />
			</Row>
			<Row className="mb-1">
				<Form.Label>Latency URLs</Form.Label>
				<p>Enter the URLs separated by comma (,) or a space. Non valid URLs will be ignored.</p>
				<Form.Control as="textarea" rows={5} value={latencyUrls} onChange={e => {setLatencyUrls(e.target.value)}} />
				<p>Valid URLs: {[...latencyUrls.toLowerCase().matchAll(urlRegExp)].join(', ')}</p>
			</Row>
			<Row className="mb-1">
				<Col md={2}><Button variant="primary" onClick={submitConfig} className='mt-4'>Submit</Button></Col>
			</Row>
		</Form>;
	}

	async function submitConfig() {
		setLoading(true);
		let onts = snForcing7g.toUpperCase().matchAll(/\w+/gi);
		onts = [...onts].map(m => m[0]);
		let urls = latencyUrls.toLowerCase().matchAll(urlRegExp);
		urls = [...urls].map(m => m[0]);
		const newConfig = [
			{
					name: "onts7gig",
					value: onts
			},
			{
				name: "latencyUrls",
				value: urls
		}
		];

		try {
			await setSmithConfig(newConfig);
			setSuccess(true);
		}
		catch (e) {
			setError(e.toString());
		}
		setLoading(false);
	}
	
	return (
		<Card>
			<header>Agent Smith Config</header>
			<section>
				{ displayMessage }
				{ content }
			</section>
		</Card>
	)
}

