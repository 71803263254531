import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Alert } from "react-bootstrap";
import axios from "axios";
import Card from '../card/Card'

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../../msAuthConfig';

export default function Login({ setToken }) {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLoginRedirect = () => {
    console.log('In progress: ' + inProgress);
    console.log('Is authenticated: ' + isAuthenticated);
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return(
    <Card>
      <header>Login</header>
      <section>
        <UnauthenticatedTemplate>
          <a className="btn btn-primary" onClick={handleLoginRedirect}>Login with Frontier Corp ID</a>
        </UnauthenticatedTemplate>
      </section>
    </Card>
  )
}