import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { default as RunTest } from "../components/fibertest/fibertestRunTest";
import { default as History } from "../components/fibertest/fibertestGetHistory";

export default function Smith({}) {
  return (
    <div className="mqtt-control-server fibertest">
      <h2>Fibertest</h2>

      <Tab.Container id="fibertest" defaultActiveKey="runTest">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="runTest">Run Fibertest</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="history">History</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="runTest">
                <RunTest />
              </Tab.Pane>
              <Tab.Pane eventKey="history">
                <History />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
