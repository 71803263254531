import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Card from "../../components/card/Card";
import { default as Chart} from "./Chart"
import { formatDates, getFibertestHistory } from "../../utils/psi-ont-api.js"

function Checkbox({name, value, isChecked, onCheck})
    {
        return (
            <div>
                <Form.Check
                    type="checkbox"
                    checked={isChecked}
                    onChange={onCheck}
                    value={value}
                    label={name}
                />
            </div>
          );
    }

export default function History({ }) 
    {
        console.log('Starting render...');
        const [history, setHistory] = useState({});
        const [filteredHistory, setFilteredHistory] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(false);
        const [errorDisplay, setErrorDisplay] = useState(false);
        const [nonFibertestDisplay, setNonFibertestDisplay] = useState(false);
        const [fibertestDisplay, setFibertestDisplay] = useState(true);
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 6);

        let [start, end] = formatDates(startDate, new Date());
        const [dates, setDates] = useState({
                startDate: start,
                endDate: end
            });

        const onDateChange = (dates) => 
            {
                console.log('Changing date...');
                console.log(dates);
                let [start, end] = dates;
                if(!end)
                    {end = new Date();}
                [start, end] = formatDates(start, end);
                setDates(
                    {
                        startDate: start,
                        endDate: end
                    });
                //getHistory(dates);
            };

        useEffect(() =>
            {
                getHistory(dates);
                return function cleanup()
                    {console.log('Cleaning up...');}
            }, [dates])
        
        const filterHistory = () =>
            {
                console.log(`Starting to filter results...`);
                console.log(history);
                let historyCopy = Object.assign({}, history);
                console.log(historyCopy);
                let counts = historyCopy.counts;
                let codes = historyCopy.codes;
                for(let year in counts)
                    {
                        console.log(`Checking year ${year} while filtering results...`);
                        for(let month in counts[year])
                            {
                                console.log(`Checking month ${month} while filtering results...`);
                                for(let day in counts[year][month])
                                    {
                                        console.log(`Checking day ${day} while filtering results...`);
                                        if(!errorDisplay && 'error' in counts[year][month][day])
                                            {
                                                console.log(`Error not selected but found in results...`);
                                                delete(counts[year][month][day]['error']);
                                                codes.splice(codes.indexOf('error'), 1);
                                            }
                                        if(!nonFibertestDisplay && 'Not Fibertest' in counts[year][month][day])
                                            {
                                                console.log(`Not Fibertest not selected but found in results...`);
                                                delete(counts[year][month][day]['Not Fibertest']);
                                                codes.splice(codes.indexOf('Not Fibertest'), 1);
                                            }
                                        if(!fibertestDisplay)
                                            {
                                                for(let code in counts[year][month][day])
                                                    {
                                                        if(code !== 'error' && code !== 'Not Fibertest')
                                                            {
                                                                console.log(`Code ${code} not selected but found in results...`);
                                                                delete(counts[year][month][day][code]);
                                                                codes.splice(codes.indexOf(code), 1);
                                                            }
                                                    }
                                            }
                                    }
                            }
                    }
                setFilteredHistory({counts: counts, codes: codes});
            }
        
        const getHistory = async (dates) =>
            {
                setLoading(true);
                setError(false);
                //Get history
                console.log(dates);
                let r = await getFibertestHistory(dates);
                let results = r?.data || {};
                console.log(results);
                if(results?.results)
                    {
                        let codeCounts = {};
                        let codes = [];
                        let i = 1;
                        for(let document of results.results)
                            {
                                let docData = document?._source;

                                //Get document date information
                                let docDate = new Date(docData.recordTimeStamp*1000);
                                let docYear = docDate.getFullYear();
                                let docMonth = docDate.getMonth() + 1; //0 indexed month
                                let docDay = docDate.getDate();

                                //Initialize date keys
                                if(!codeCounts[docYear])
                                    {codeCounts[docYear] = {};}
                                if(!codeCounts[docYear][docMonth])
                                    {codeCounts[docYear][docMonth] = {};}
                                if(!codeCounts[docYear][docMonth][docDay])
                                    {codeCounts[docYear][docMonth][docDay] = {};}

                                //Process the document
                                let resultCode = 'Not Fibertest';
                                if('fiberTestResult' in docData)
                                    {resultCode = docData.fiberTestResult;}
                                else if('error' in docData)
                                    {resultCode = 'error';}
                                if(!codes.includes(resultCode))
                                    {codes.push(resultCode);}
                                if(!codeCounts[docYear][docMonth][docDay][resultCode])
                                    {codeCounts[docYear][docMonth][docDay][resultCode] = 0;} //Initialize the count for specific result code on that day
                                codeCounts[docYear][docMonth][docDay][resultCode]++; //Increment the count for result code
                            }
                        if(Object.keys(codeCounts).length === 0)
                            {setError('No results found for specified time period.');}
                        else
                            {
                                setFilteredHistory({counts: codeCounts, codes: codes});
                                //setHistory({counts: codeCounts, codes: codes}, filterHistory()); //Filtering function - callback isn't functioning as expected.
                            }
                    }
                else
                    {setError('There was an error pulling the results.');}
                setLoading(false);
            }

        const render = () =>
            {
                if(loading)
                    {return <h2 className="mt-5">Loading...</h2>;}
                else
                    {
                        return(
                        <Card>
                            <header>History</header>
                            <section>
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <DatePicker
                                                selected={dates.startDate}
                                                onChange={onDateChange}
                                                maxDate={new Date()}
                                                selectsRange
                                                startDate={dates.startDate}
                                                endDate={dates.endDate}
                                                //className="home-datepicker"
                                            />
                                        </Col>
                                        {
                                        /*
                                        //Filtering checkboxes - work in progress
                                        <Col><Checkbox value="fibertest" name="Fibertest" isChecked={fibertestDisplay} onCheck={() => setFibertestDisplay(!fibertestDisplay, filterHistory())} /></Col>
                                        <Col><Checkbox value="errors" name="Errors" isChecked={errorDisplay} onCheck={() => setErrorDisplay(!errorDisplay, filterHistory())} /></Col>
                                        <Col><Checkbox value="nonFibertest" name="Non-Fibertest" isChecked={nonFibertestDisplay} onCheck={() => setNonFibertestDisplay(!nonFibertestDisplay, filterHistory())} /></Col>
                                        */
                                        }
                                    </Row>
                                </Container>
                            </section>
                            <section>
                                {
                                    error ? 
                                    <h2 className="mt-5">{error}</h2> :
                                    <Chart data={filteredHistory} />
                                }
                            </section>
                        </Card>);
                    }
            }
        return render();
    }