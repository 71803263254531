import React, { useState, createContext, useContext } from "react";
import GaugeComponent  from 'react-gauge-component'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from "../../components/card/Card";
import { getFibertest, startFibertest } from "../../utils/psi-ont-api.js";

import 'react-bootstrap-typeahead/css/Typeahead.css';

const testTypes = 
    {
        'fibertest': 'Full Test',
        'unitAddress': 'Unit Address',
        'status': 'Status',
        'distance': 'Distance',
        'uptime': 'Uptime',
        'lightlevel': 'Light Levels',
        'software': 'Software',
        'softwareUpdate': 'Software Update',
        'ber': 'BER',
        'card': 'Card/Port'
    }

const FibertestContext = createContext();

function Checkbox({name, onCheck, value, className})
    {
        const fibertest = useContext(FibertestContext);
        return (
            <div>
                <Form.Check
                    className={className || ''}
                    type="checkbox"
                    //checked={isChecked}
                    onChange={onCheck}
                    value={value}
                    label={name}
                    checked={fibertest ? fibertest : null}
                    disabled={value === 'fibertest' ? '' : fibertest}
                />
            </div>
          );
    }

export default function Fibertest({ }) 
    {
        const [response, setResponse] = useState('');
        const [runningTest, setRunningTest] = useState(false);
        const [tests, setTests] = useState([]);
        const [serial, setSerial] = useState('');
        const [fibertest, setFibertest] = useState(true);

        function toggleFibertest(element)
            {setFibertest(!fibertest);}

        function toggleCheckbox(element)
            {
                let value = element.value;
                let checked = element.checked;
                let updatedTests = [...tests];
                if(checked && !updatedTests.includes(value))
                    {updatedTests.push(value);}
                if(!checked && updatedTests.includes(value))
                    {
                        while(updatedTests.includes(value))
                            {updatedTests.splice(updatedTests.indexOf(value), 1);}
                    }
                setTests(updatedTests);
            }
        
        const makeRequest = async (sn, tests, i) =>
            {
                try
                    {
                        console.log('Check ' + i + '...');
                        let res;
                        if(i == 0)
                            {
                                setResponse('Requesting test...');
                                res = await startFibertest(sn, tests);
                            }
                        else
                            {
                                setResponse('Checking test status...');
                                res = await getFibertest(sn);
                            }
                        //logObj(res);
                        let status = res?.status || '';
                        let message = res?.data?.message || res?.data?.error || '';
                        let data = res?.data;
                        console.log('Status: ' + status);
                        console.log('Message: ' + message);
                        if(status >= 400)
                            {
                                setResponse(`There was an error running a fibertest for ONT ${sn}:
                                ${message}`);
                                setRunningTest(false);
                            }
                        else if(status === '200'|| status === 200)
                            {
                                //setResponse(res.data);
                                let tableData = {};
                                data?.fiberTestResult && (tableData["Fibertest Result"] = data?.fiberTestResult);
                                data?.ontSerial && (tableData["Serial"] = data?.ontSerial);
                                data?.clli && (tableData["CLLI"] = data?.clli);
                                data?.unitAddress && (tableData["Unit Address"] = data?.unitAddress);
                                data?.adminStatus && (tableData["Admin Status"] = data?.adminStatus);
                                data?.operStatus && (tableData["Operational Status"] = data?.operStatus);
                                data?.voltage && (tableData["Voltage"] = data?.voltage);
                                data?.laserBias && (tableData["Laser Bias"] = data?.laserBias);
                                data?.berUp && (tableData["Ber Up"] = data?.berUp);
                                data?.berDown && (tableData["Ber Down"] = data?.berDown);
                                data?.tx && (tableData["ONT Tx"] = data?.tx);
                                data?.oltRx && (tableData["OLT Rx"] = data?.oltRx);
                                
                                setResponse(
                                    <div style={{flexGrow: "1", overflow: "hidden", display: "flex", flexDirection: "column", maxWidth: "30vw", gap: "50px"}}>
                                        <Gauge data={data} />
                                        <div style={{justifyItems: 'center'}}><p>Distance:   {data?.distance}</p></div>
                                        <LinearGauge temp={res.data.temp} />
                                        <Table data={tableData} />
                                        <CardCarousel style={{overflow: "auto"}} cards={data?.cards} />
                                    </div>
                                );
                                setRunningTest(false);
                            }
                        else if(i > 10)
                            {
                                setResponse(`No response was received for ONT ${sn} after ${i*5}s.`);
                                setRunningTest(false);
                            }
                        else
                            {
                                i++;
                                setResponse('The test is still running. Checking again in 5 seconds...');
                                setTimeout(() => makeRequest(sn, tests, i), 5000);
                            }
                    }
                catch(err)
                    {
                        setResponse(`There was an error communicating with the fibertest servers: ${err}`);
                        setRunningTest(false);
                    }
            }

        function Table ({data})
            {
                let rows = [];
                for(let key in data)
                    {
                        let value = data[key];
                        if(typeof value !== 'object')
                            {
                                rows.push(
                                    <tr style={{border: "1px solid white"}}>
                                        <th style={{border: "1px solid white"}}>{key}</th>
                                        <td style={{border: "1px solid white"}}>{value}</td>
                                    </tr>)
                            }
                    }

                return (
                    <div>
                        <table style={{border: "1px solid white", borderCollapse: "collapse", width: "100%"}}>
                            {rows}
                        </table>
                    </div>
                )
            }

        function Gauge({data})
            {
                let guageData = data?.rxRanges?.gauge;
                console.log(data);
                let range = 30;
                let arcs = [];
                if(guageData)
                    {
                        arcs = 
                            [
                                {
                                    limit: guageData.redCold[0],
                                    color: 'blue',
                                    showTick: true,
                                    tooltip:
                                        {text: 'Light is too cold.'}
                                },
                                {
                                    limit: guageData.yellowCold[0],
                                    color: 'yellow',
                                    showTick: true,
                                    tooltip:
                                        {text: 'Light is borderline too cold.'}
                                },
                                {
                                    limit: guageData.green[0],
                                    color: 'green',
                                    showTick: true,
                                    tooltip:
                                        {text: 'Light is in the goldilocks zone.'}
                                },
                                {
                                    limit: guageData.redHot[0],
                                    color: 'yellow',
                                    showTick: true,
                                    tooltip:
                                        {text: 'Light is borderline too hot.'}
                                },
                                {
                                    limit: 0,
                                    color: 'red',
                                    showTick: true,
                                    tooltip:
                                        {text: 'Light is too hot.'}
                                },
                            ]
                    }
                else
                    {return;}


                console.log(arcs);
                
                return (
                    <GaugeComponent
                        minValue={-30}
                        maxValue={0}
                        type='semicircle'
                        arc={{
                            //gradient: true,
                            subArcs: arcs,
                            cornerRadius: 1,
                            padding: 0.005,
                        }}
                        pointer={{
                            length: 1,
                            animationDelay: 0,
                            width: 5
                          }}
                        labels={{
                        valueLabel: { formatTextValue: value => value + ' dB' },}}
                        value={data.rx}
                    />
                )
            }

        function LinearGauge({temp})
            {
                if(!temp)
                    {return;}
                /*
                temp = 20;
                let hashes = 
                    <div style={{display: "flex", flexDirection: "row", height: "10px"}}>
                        <div style={{width: "3px", backgroundColor: "white", height: "100%", position: "relative", left: "0%"}}>0</div>
                        <div style={{width: "3px", backgroundColor: "white", height: "100%", position: "relative", right: "0%"}}>100</div>
                    </div>;
                */
                let hashes;
                let greenWidth = 60;
                let yellowWidth = 20;
                let redWidth = 100 - yellowWidth - greenWidth;
                return (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{position: "relative", left: `${temp - 2.5}%`, width: "fit-content", color: `${temp <= greenWidth ? 'green' : (temp <= greenWidth + yellowWidth ? 'yellow' : 'red')}`}}>{temp}&deg;</div>
                        <div style={{display: "flex", flexDirection: "row", height: "20px"}}>
                            <div style={{width: "3px", backgroundColor: "black", height: "100%", position: "relative", left: `${temp}%`}}></div>
                            <div style={{backgroundColor: "green", borderBottomLeftRadius: "5px", borderTopLeftRadius: "5px", paddingRight: "1px", width: `${greenWidth}%`, marginRight: "2px"}}></div>
                            <div style={{backgroundColor: "yellow", paddingRight: "1px", width: `${yellowWidth}%`, marginRight: "2px"}}></div>
                            <div style={{backgroundColor: "red", borderBottomRightRadius: "5px", borderTopRightRadius: "5px", width: `${redWidth}%`}}></div>
                        </div>
                        {hashes}
                    </div>
                )
            }
        
        function CardCarousel({cards})
            {
                let carousel = [];
                for(let card in cards)
                    {
                        let cardData = cards[card];
                        carousel.push(<CardItem key={card} card={card} cardData={cardData} />);
                    }
                return <div style={{display: "flex", flexDirection: "column", gap: "10px", overflow: "auto"}} >{carousel}</div>;
            }

        function CardItem({card, cardData})
            {
                console.log(`Creating card for card ${card}`);
                let cardType = cardData?.type;
                let cardStatus = cardData?.adminStatus;
                return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap:"5px"}}><center style={{borderBottom: "1px solid white", color: `${cardStatus === 'up' ? 'green' : (cardStatus === 'down' ? 'red' : 'yellow')}`}}>Card {card}{cardType ? ` - ${cardType}` : ''}{cardStatus ? ` - ${cardStatus}` : ''}</center><div style={{display: "flex", flexDirection: "row", gap:"5px"}}><PortCarousel ports={cardData?.ports} /></div></div>;
            }
        
        function PortCarousel({ports})
            {
                console.log(`Creating port carousel...`);
                let carousel = [];
                for(let port in ports)
                    {
                        let portData = ports[port];
                        carousel.push(<PortItem key={port} port={port} portData={portData} />);
                    }
                return <div style={{display: "flex", flexDirection: "row", gap: "10px", overflow: "auto"}} >{carousel}</div>;
            }

        function PortItem({port, portData})
            {
                console.log(`Creating card for port ${port}`);
                console.log(portData);
                let portStatus = portData?.operStatus;
                let tableData = {};
                if(portData?.registration)
                    {
                        tableData = 
                            {
                                'Number': portData?.['user-name'],
                                'Registration': portData?.registration,
                                'Status': portData?.status
                            }
                    }
                if(portData?.operStatus)
                    {
                        tableData = 
                            {
                                'Rate (Mbps)': portData?.rate,
                                'Duplex': portData?.duplex,
                                'MAC': portData?.mac,
                                'QoS': portData?.qos,
                                'S Tag': portData?.stag,
                                'C Tag': portData?.ctag,
                            }

                    }
                return (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <center style={{borderBottom: "1px solid white", color: `${portStatus === 'up' ? 'green' : (portStatus === 'down' ? 'red' : 'white')}`}}>Port {port}{portStatus ? ` - ${portStatus}` : ''}</center>
                        <Table data={tableData} />
                    </div>)
            }

        function submitForm(event)
            {
                event.preventDefault(); //Prevents the page from reloading
                setRunningTest(true);
                setResponse('Starting the test...');
                makeRequest(serial, tests.join(','), 0);
            }
        
        let checkboxes = [];
        for(let testType in testTypes)
            {
                let testName = testTypes[testType];
                checkboxes.push(<Checkbox className="checkbox" value={testType} name={testName} onCheck={(e) => {testType === 'fibertest' ? toggleFibertest(e.target) : toggleCheckbox(e.target)}}/>);
            }
            

        return (
            <Card>
                <section>
                    <FibertestContext.Provider value={fibertest}>
                        <Container fluid>
                            <Row>
                                <Col className="fibertest-form">
                                    <Form onSubmit={(event) => submitForm(event)}>
                                        <Row className="mb-3">
                                            <Form.Group className="mb-3" as={Col} md="3" style={{width: "fit-content"}}>
                                                <Form.Label>Serial Number</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Device Serial Number" value={serial} onChange={e => setSerial(e.target.value)} required />
                                            </Form.Group>
                                            <Form.Group className="test-type">
                                                <Form.Label>Type of Test</Form.Label>
                                                {checkboxes}
                                            </Form.Group>
                                        </Row>
                                        <Button variant="primary" type="submit" disabled={runningTest}>
                                            Submit
                                        </Button>
                                    </Form>
                                </Col>
                                <Col style={{overflow: "auto", maxHeight: "60vh"}} >
                                    {response}
                                </Col>
                            </Row>
                        </Container>
                    </FibertestContext.Provider>
                </section>
            </Card>
        )
    }