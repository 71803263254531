import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import Card from '../../card/Card'
import DailyChart from "./DailyChart";
import DailySessionsChart from "./DailySessionsChart";
import SessionErrorsTable from "./SessionErrorsTable";
import { getAccessToken } from "../../../utils/auth";

const apiEndpoint = 'https://ont.api.fybrlabs.frontier.com/Prod/v2/reports/ont';
//const apiEndpoint = 'http://localhost:3000/v2/reports/ont';

export default function TechniciansReport ({ dateRange }) {
  const [data, setData] = useState({techId: {}});
  const [loading, setLoading] = useState(true);
  let interval = null;

  const getReport = async () => {
    setLoading(true);
    let url = apiEndpoint + `?startTime=${dateRange.startDate.getTime()}`;
    if (dateRange.endDate) {
      url += `&endTime=${dateRange.endDate.getTime()}`;
    }
    const token = await getAccessToken();
    let r = await axios.get(url, {headers: {'auth-token': token}});
    setData(r.data);
    setLoading(false);
  }
  useEffect(() => {
    getReport();
    if (!interval) {
      //interval = setInterval(getReport, 10000);
    }
    return function cleanup() {
      clearInterval(interval);
      interval = null;
    };
  }, [dateRange]);

  function renderContent() {
    if (loading) {
      return <h2 className="mt-5">Loading...</h2>;
    } else {
      const totalSessions = data.sessions.count.total;
      const errorSessions = data.sessions.count.errors;
      const successRate = (totalSessions - errorSessions) * 100 / totalSessions;
      return <Row className="mt-5">
        <Col lg={6}>
          <Row>
            <Col lg={6}>
              <Card className={'tech-count'}>
                <header>Sessions</header>
                <section>{totalSessions}</section>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className={'tech-count'}>
                <header>Success Rate</header>
                <section>{successRate.toFixed(2) + '%'}</section>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <DailySessionsChart data={data.sessions.days} />
              <SessionErrorsTable sessions={data.sessions.list} />
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <Row>
            <Col lg={6}>
              <Card className={'tech-count'}>
                <header>Unique Techs</header>
                <section>{Object.keys(data.techId.counters).length}</section>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className={'tech-count'}>
                <header>Unique ONTs</header>
                <section>{Object.keys(data.serialNumber.counters).length}</section>
              </Card>
            </Col>
          </Row>
          <DailyChart data={data.techId.days} title="Unique Technicians Per Day" seriesLabel="Technicians" />
          <DailyChart data={data.serialNumber.days} title="Unique ONTs Per Day" seriesLabel="ONTs" />
        </Col>
      </Row>;
    }
  }

  return renderContent();
}

