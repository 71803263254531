
/**
 * @param {Array} rows An array (rows) of arrays (cells).
 * @param {String} fileName The file name.
 */
export function exportToCsv(rows, fileName) {
  if (!Array.isArray(rows)) {
    throw {
      message: 'rows must be an array of arrays.'
    }
  }

  const content = rows
    .map((cells) => cells.map(serialiseCellValue).join(','))
    .join('\n');

  downloadFile(fileName, new Blob([content], { type: 'text/csv;charset=utf-8;' }));
}

function serialiseCellValue(value) {
  if (typeof value === 'string') {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(',') ? `"${formattedValue}"` : formattedValue;
  }
  return value;
}

function downloadFile(fileName, data) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}