import Card from '../../card/Card';
import ReactApexChart from 'react-apexcharts';

export default function DailySessionsChart ({ data }) {
  console.log(data);
  let days = Object.keys(data);
  days = days.sort();
  let chartData = {success: [], errors: []};
  days.forEach(d => {
    chartData.success.push(data[d].success);
    chartData.errors.push(data[d].errors);
  });
  let chartOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      background: 'transparent'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: '',
      align: 'left'
    },
    xaxis: {
      categories: days,
    },
    yaxis: {
      min: 0,
      forceNiceScale: true
    },
    theme: {
      mode: 'dark'
    }
  };
  let series = [
    {
      name: 'Success',
      data: chartData.success
    },
    {
      name: 'Error',
      data: chartData.errors
    }
  ];

  return(
    <Card>
      <header>Sessions per day</header>
      <section>
        <ReactApexChart options={chartOptions} series={series} type="line" height={258}></ReactApexChart>
      </section>
    </Card>
  )
}
