import axios from "axios";
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from "../msAuthConfig";

export const apiAuth = async () => {
  let url = 'https://auth.api.fybrlabs.frontier.com/Prod/v1/login';
  //let url = 'http://localhost:3000/v1/login';
  const token = await getAccessToken();
  console.log('token: ', token);
  let r = await axios.post(url, null, {headers: { 'auth-token': token }});
  return r.data;
}

export const getAccessToken = async () => {
  const pca = new PublicClientApplication(msalConfig);
  const accounts = pca.getAllAccounts();
  const account = accounts[0];
  const accessTokenRequest = {
    scopes: ["user.read"],
    account: account,
  };
  const resp = await pca.acquireTokenSilent(accessTokenRequest);
  console.log('Get token response', resp)
  return resp.accessToken;
  // return resp.idToken;
}