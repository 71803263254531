import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TechniciansReport from "../components/dashboard/TechniciansReport";
import OltSessions from "../components/vqcReports/OltSessions";
import SpeedTestResults from "../components/rg/SpeedTestResults";

export default function Home({ token }) {
  let startDate = new Date();
  startDate.setDate(startDate.getDate() - 6);
  let [start, end] = formatDates(startDate, new Date());
  const [dates, setDates] = useState({
    startDate: start,
    endDate: end
  });
  const onDateChange = (dates) => {
    let [start, end] = dates;
    [start, end] = formatDates(start, end);
    
    setDates({
      startDate: start,
      endDate: end
    });
  };

  function formatDates (start, end) {
    if (!start) {
      start = new Date();
    }
    start.setHours(0, 0, 0, 0);
    if (end) {
      end = new Date();
      end.setHours(23, 59, 59, 999);
    }
    return [start, end]
  }

  return (
    <>
      <DatePicker
        selected={dates.startDate}
        onChange={onDateChange}
        maxDate={new Date()}
        selectsRange
        startDate={dates.startDate}
        endDate={dates.endDate}
        className="home-datepicker"
      />
      <TechniciansReport dateRange={dates} />
      <OltSessions />
      <SpeedTestResults />
    </>
  );
}
