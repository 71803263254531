import Card from '../../card/Card';
import ReactApexChart from 'react-apexcharts';

export default function DailyChart ({ data, title, seriesLabel }) {
  console.log(data);
  let days = Object.keys(data);
  days = days.sort();
  let chartData = [];
  days.forEach(d => {
    chartData.push(Object.keys(data[d]).length);
  });
  let chartOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      background: 'transparent'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: '',
      align: 'left'
    },
    xaxis: {
      categories: days,
    },
    yaxis: {
      min: 0,
      forceNiceScale: true
    },
    theme: {
      mode: 'dark'
    }
  };
  let series = [{
    name: seriesLabel,
    data: chartData
  }];

  return(
    <Card>
      <header>{title}</header>
      <section>
        <ReactApexChart options={chartOptions} series={series} type="line" height={258}></ReactApexChart>
      </section>
    </Card>
  )
}
