import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Recommendation from "./recommendation";
import { putAlert } from "../../../utils/cms-apis";
import { getAccessToken } from "../../../utils/auth";

export default function Alert({ name, message, condition, displayTypes, recommendations, keyProp, deleteAlert}) {
  const [n, setName] = useState(name);
  const [m, setMessage] = useState(message);
  const [c, setCondition] = useState(condition);
  const [dTypes, setDisplayTypes] = useState(displayTypes || {});
  const [recs, setRecommendations] = useState(recommendations);

  const conditions = {
    'Fiber Not Connected': '!info.connected',
    'Cold Light Level': 'info.operationalStatus == "up" && info.ontRxSignalLevel < info.yellowColdHigh',
    'Hot Light Level': 'info.connected && info.ontRxSignalLevel > info.yellowHotLow',
    'Ethernet Port Half Duplex': "!info.portsInfo.isEmpty && info.portsInfo.any((item) => item.linkMode != 'full')"
  }

  function getSelectedCondition(value) {
    let condition = '';
    for (let c in conditions) {
      if (conditions[c] === value) {
        condition = c;
        break;
      }
    }
    return condition;
  }


  function addNewRecommendation() {
    console.log('Adding new recommendation');
    let nextKey = 0;
    recs.forEach(recommendation => {
      nextKey = nextKey - recommendation.keyNumber > 0 ? nextKey + 1 : recommendation.keyNumber + 1;
    });
    let newRecommendation = {
      keyNumber: nextKey,
      key: keyProp + '_' + nextKey,
      title: keyProp + '_' + nextKey,
      description: '',
      image: ''
    }
    setRecommendations([...recs, newRecommendation ]);
  }

  function deleteRecommendation(key) {
    let newRecommendations = [];
    recs.forEach(recommendation => {
      if (recommendation.key !== key) {
        newRecommendations.push(recommendation);
      }
    });
    setRecommendations(newRecommendations);
  }

  function updateRecommendation(key, title, description, image) {
    let recommendation = recs.filter(rec => {
      return rec.key === key;
    })[0];
    recommendation.title = title;
    recommendation.description = description;
    recommendation.image = image;
  }

  async function saveAlert() {
    let a = {
      name: n,
      message: m,
      condition: c,
      displayTypes: Object.keys(dTypes).filter(type => dTypes[type]),
      recommendations: recs
    }
    console.log('Saving alert: ', a);
    const token = await getAccessToken();
    let r = await putAlert(a, token);
  }

  return <Form className="cms-alert">
    <Row>
      <Col md={5}>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" value={n} onChange={e => {setName(e.target.value)}}/>
        </Form.Group>
      </Col>
      <Col md={5}>
        <Form.Group>
          <Form.Label>Condition</Form.Label>
          <Form.Select onChange={e => {setCondition(conditions[e.target.value])}} >
            {Object.keys(conditions).map(condition => <option value={condition} selected={c === conditions[condition]}>{condition}</option>)}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group>
          <Form.Label>Display types</Form.Label>
          <Form.Check type="checkbox" label="Voice" value={'voice'} checked={dTypes.voice} onChange={e => {dTypes.voice = e.target.checked;}} />
          <Form.Check type="checkbox" label="Modal" value={'modal'} checked={dTypes.modal} onChange={e => {dTypes.modal = e.target.checked;}} />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>Message</Form.Label>
          <Form.Control type="text" value={m} onChange={e => {setMessage(e.target.value)}}/>
        </Form.Group>
      </Col>
    </Row>
    <h4 className="mt-5">Recommendations</h4>
    <Row className="mb-5">
      <Col style={{textAlign: 'right'}} ><Button variant="info" onClick={addNewRecommendation}>Add New Recommendation</Button></Col>
    </Row>
    {recs.map(recommendation => {
      return <Recommendation title={recommendation.title} description={recommendation.description}
        image={recommendation.image} imageUrl={recommendation.imageUrl} key={recommendation.key} keyProp={recommendation.key}
        deleteRecommendation={deleteRecommendation} updateRecommendation={updateRecommendation} />
    })}

    <Row className="mt-5">
      <Col md={2}><Button variant="primary" onClick={saveAlert}>Save Alert</Button></Col>
      <Col md={2}><Button variant="danger" onClick={deleteAlert.bind(null, keyProp)}>Delete Alert</Button></Col>
    </Row>
  </Form>;
}
