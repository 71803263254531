import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import axios from "axios";
import { getAccessToken } from "../../utils/auth";

const apiEndpoint = 'https://mqtt.api.fybrlabs.frontier.com/Prod/v1';
//const apiEndpoint = 'http://localhost:3000';
let authToken;

async function handleFormSubmission(sn, command, setResp, event) {
  event.preventDefault();
  console.log(sn + ', ' + command);

  if (command === '0') {
    setResp('');
    return;
  }

  setResp('Loading...');

  let r;

  try {
    authToken = await getAccessToken();
    r = await axios.get(`${apiEndpoint}/device/${sn}/${command}`, {timeout: 10000, headers: {'auth-token': authToken}})
    console.log('response: ', r.data);
    setResp(r.data);
  } catch(e) {
    if (e.code === 'ECONNABORTED') {
      setResp({
        message: e.message,
        description: `Device ${sn} did not respond.`
      });
    } else {
      console.error(e.code);
      console.log('error: ', e);
      let error;
      if (e.response && e.response.data) {
        error = e.response.data;
      } else {
        error = e;
      }
      setResp(error);
    }
  }
}

async function getCommands(setCommands) {
  try {
    authToken = await getAccessToken();
    let r = await axios.get(`${apiEndpoint}/commands`, {timeout: 5000, headers: {'auth-token': authToken}});
    console.log('response: ', r.data);
    setCommands(r.data);
  } catch(e) {
  }
}

export default function GetDataFromDeviceForm({ }) {
  const [command, setCommand] = useState('0');
  const [sn, setSN] = useState('');
  const [resp, setResp] = useState(null);
  const [commands, setCommands] = useState([]);

  useEffect(() => {
    getCommands(setCommands)
  }, [])

  let response;

  if (resp) {
    response = <div className="response mt-4"><h4>Response: </h4><pre>{JSON.stringify(resp, null, 2)}</pre></div>
  }
  else {
    response = false;
  }

  const commandOptions = commands.map((c) => {
    return <option key={'option-' + c} value={c}>{c}</option>
  })

  return (
    <div className="mqtt-form">
      <h3>Get diagnostic parameter from a Device</h3>
      <Form onSubmit={handleFormSubmission.bind(null, sn, command, setResp)}>
        <Row className="mb-3">
          <Form.Group className="mb-3" as={Col} md="3">
            <Form.Label>Serial Number</Form.Label>
            <Form.Control type="text" placeholder="Enter Device Serial Number" value={sn} onChange={e => setSN(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" as={Col} md="3">
            <Form.Label>Parameter to get</Form.Label>
            <Form.Select value={command} required onChange={e => {
            console.log("e.target.value", e.target.value);
            setCommand(e.target.value);
          }}>
              <option value="0">Choose a command</option>
              {commandOptions}
            </Form.Select>
          </Form.Group>
        </Row>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      {response}
    </div>
  )
}
