import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";

import 'react-bootstrap-typeahead/css/Typeahead.css';

import tr181Parameters from "./tr181-parameters";
import { FormGroup } from "react-bootstrap";
import { getAccessToken } from "../../utils/auth";

const apiEndpoint = 'https://mqtt.api.fybrlabs.frontier.com/Prod/v1';
//const apiEndpoint = 'http://localhost:3000/v1';

async function handleFormSubmission(sn, command, indexes, action, value, setResp, event) {
  event.preventDefault();
  if (command[0] === '') {
    setResp('');
    return;
  }

  let param = command[0];

  indexes.forEach(val => {
    param = param.replace('{i}', val);    
  });

  setResp('Loading...');

  console.log(`Sending request to ${sn} for param: ${param}`);

  let r;
  let v;

  try {
    v = JSON.parse(value);
  } catch(e) {
    v = value;
  }

  try {
    const authToken = await getAccessToken();
    if (action === 'get') {
      r = await axios.get(`${apiEndpoint}/device/${sn}/TR181/${param}`, {timeout: 10000, headers: {'auth-token': authToken}});
    } else if (action === 'set') {
      r = await axios.post(`${apiEndpoint}/device/${sn}/TR181/${param}`, {value: v}, {timeout: 10000, headers: {'auth-token': authToken}});
    }
    
    console.log('response: ', r.data);
    setResp(r.data);
  } catch(e) {
    if (e.code === 'ECONNABORTED') {
      setResp({
        message: e.message,
        description: `Device ${sn} did not respond.`
      });
    } else {
      console.error(e.code);
      console.log('error: ', e);
      let error;
      if (e.response && e.response.data) {
        error = e.response.data;
      } else {
        error = e;
      }
      setResp(error);
    }
  }
}

function setIndexesWrapper(indexes, newValue, index, setIndexes) {
  let tempIndexes = [...indexes];
  tempIndexes[index] = newValue;
  setIndexes(tempIndexes);
}

export default function TR181Form({}) {
  const [command, setCommand] = useState([]);
  const [sn, setSN] = useState('');
  const [resp, setResp] = useState(null);
  const [indexes, setIndexes] = useState([]);
  const [action, setAction] = useState('get');
  const [value, setValue] = useState('');

  let response;

  if (resp) {
    response = <div className="response mt-4"><h4>Response: </h4><pre>{JSON.stringify(resp, null, 2)}</pre></div>
  }
  else {
    response = false;
  }

  const countOfIndexes = (command.length && command[0].match(/{i}/g) || []).length;

  let indexFields;
  if (countOfIndexes) {
    let iTextFields = [];
    let keyPreffix = Date.now();
    for (let i = 0; i < countOfIndexes; i++) {
      iTextFields.push(<Form.Group className="mb-3" as={Col} md="2" key={keyPreffix + '-indexfield-' + i}>
      <Form.Label>Value for index {i+1}</Form.Label>
      <Form.Control type="number" placeholder="Enter index value here" value={indexes[i]} onChange={e => setIndexesWrapper(indexes, e.target.value, i, setIndexes) } />
    </Form.Group>)
    }
    indexFields = <Form.Group className="index-fields" as={Row}>{iTextFields}</Form.Group>;
  }

  let setValueForm;
  if (action === 'set') {
    setValueForm = <Form.Group className="mb-3" as={Col} md="9">
      <Form.Label>Value</Form.Label>
      <Form.Control type="text" placeholder="Enter Value" value={value} onChange={e => setValue(e.target.value)} required />
    </Form.Group>;
  }

  return (
    <div className="mqtt-form">
      <h3>TR181 Parameters</h3>
      <Form onSubmit={handleFormSubmission.bind(null, sn, command, indexes, action, value, setResp)}>
        <Row className="mb-3">
          <Form.Group className="radio-buttons-group">
            <Form.Label>Action</Form.Label>
            <Form.Check type='radio' className="radio-buttons" id={`tr181-action-get`} value='get' label={`GET`} name='tr181-action' checked={action === 'get'} onChange={e => setAction(e.target.value)}/>
            <Form.Check type='radio' className="radio-buttons" id={`tr181-action-set`} value='set' label={`SET`} name='tr181-action' checked={action === 'set'} onChange={e => setAction(e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-3" as={Col} md="3">
            <Form.Label>Serial Number</Form.Label>
            <Form.Control type="text" placeholder="Enter Device Serial Number" value={sn} onChange={e => setSN(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" as={Col} md="9">
            <Form.Label>TR181 Parameter</Form.Label>
            <Typeahead id="tr181-param-select" onChange={(selected) => {
              setCommand(selected);
            }} options={tr181Parameters}
            selected={command} />
          </Form.Group>
          {indexFields}
          {setValueForm}
        </Row>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      {response}
    </div>
  )
}
