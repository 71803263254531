import { Button, Container } from "react-bootstrap";
import Card from "../card/Card";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState } from "react";

export default function OntDetails ({ ontDetails }) {
  const [refreshing, setRefreshing] = useState(false);
  let opStatus = <p className="text-success"><b>OPERATIONAL</b></p>;
  if (ontDetails.opStatus === 'down') {
    opStatus = <p className="text-danger"><b>NOT OPERATIONAL</b></p>;
  }
  console.log(`last updated: ${ontDetails.recordTimeStamp} > ${ontDetails.timeStamp} ${ontDetails.recordTimeStamp > ontDetails.timeStamp}`);
  let lastUpdated = ontDetails.recordTimeStamp > ontDetails.timeStamp ? new Date(ontDetails.recordTimeStamp * 1000) : new Date(ontDetails.timeStamp * 1000);
  let ua = ontDetails.unitAddress || ontDetails.objectName;
  ua = ua.split(':');
  ua[1] = ua[1].replace(/[A-Z]+/g, '');
  ua[1] = ua[1].replace(/\./g, '-');
  ua = ua.join(':');

  function startRefresh() {
    setRefreshing(true);
  }

  return <Card className="spark-details">
      <header>ONT Details</header>
      <section>
        <div className="timestamp">
          <p>Last updated: {refreshing ? 'Refreshing' : lastUpdated.toLocaleDateString() + ' ' + lastUpdated.toLocaleTimeString()}</p>
          <Button variant="secondary" onClick={startRefresh}>Refresh</Button>
        </div>
        <Row>
          <Col>
            <Row>
              <Col><p>Operational Status</p></Col>
              <Col>{opStatus}</Col>
            </Row>
            <Row>
              <Col><p>ONT Serial Number</p></Col>
              <Col><p>{ontDetails.ontSerialNumber}</p></Col>
            </Row>
            <Row>
              <Col><p>ONT RX</p></Col>
              <Col><p>{ontDetails.ontRxSignalLevel} dBm</p></Col>
            </Row>
            <Row>
              <Col><p>ONT Distance to OLT</p></Col>
              <Col><p>{ontDetails.ontDistance} KM</p></Col>
            </Row>
            <Row>
              <Col><p>ONT Op. Temperature</p></Col>
              <Col><p>{ontDetails.ontOpticalTemperature} <sup>o</sup>C</p></Col>
            </Row>
          </Col>
          <Col>
            <h5>ONT Technical details</h5>
            <Row>
              <Col><p>Model</p></Col>
              <Col><p>{ontDetails.modelNumber || 'No Data'}</p></Col>
            </Row>
            <Row>
              <Col><p>ONT Status</p></Col>
              <Col><p>{ontDetails.ranged === 'Yes' ? 'Ranged' : 'Not Ranged'}</p></Col>
            </Row>
            <Row>
              <Col><p>Assigned Version</p></Col>
              <Col><p>{ontDetails.softwareStatus?.latestSoftware || 'No Data'}</p></Col>
            </Row>
            <Row>
              <Col><p>Unit Address</p></Col>
              <Col><p>{ua || 'No Data'}</p></Col>
            </Row>
          </Col>
        </Row>
      </section>
    </Card>
}