
import { Container } from "react-bootstrap";

export default function NotAllowed () {
  return (
    <Container className='pt-2 mt-5'>
      <div>
        <h2>Looks like you don't have access to the PSI Console.</h2>
      </div>
    </Container>
  );
}
