import { Button, Col, Container, Row } from "react-bootstrap";
import OntSpeedTestConfigItem from "./ontSpeedTestConfigItem";
import Card from "../../card/Card";
import { deleteOntSpeedTestConfigs, getAllOntSpeedTestConfigs } from "../../../utils/cms-apis";
import { useEffect, useState } from "react";

export default function OntSpeedTestConfigList (props) {
  const [items, setItems] = useState([]);
  const [newItemsCount, setNewItemsCount] = useState(0);

  function addNewItem() {
    const newItem = {
      key: `stConfig-${newItemsCount}`,
      _key: `stConfig-${newItemsCount}`,
      dlPort: 8080
    };
    setItems([newItem, ...items]);
    setNewItemsCount(newItemsCount + 1);
  }

  function removeItem(key) {
    let newItems = [];
    items.forEach(async item => {
      if (item.key === key) {
        try {
          await deleteOntSpeedTestConfigs(item.id);
        }
        catch (e) {
          console.log('Error deleting item', e);
        }
      }
      else {
        newItems.push(item);
      }
    });
    setItems(newItems);
  }

  function getSpeedAndOntOfConfigConditions(item) {
    let conditions = [];
    if (item.speedProfiles?.length && item.ontModels?.length) {
      item.speedProfiles.forEach(speed => {
        item.ontModels.forEach(model => {
          conditions.push(`${speed}-${model}`);
        })
      })
    }
    else if (item.speedProfiles?.length) {
      item.speedProfiles.forEach(speed => {
        conditions.push(`${speed}-all`);
      });
    }
    else if (item.ontModels?.length) {
      item.ontModels.forEach(model => {
        conditions.push(`all-${model}`);
      });
    }
    return conditions;
  }
  function validateNewItem(newItem) {
    let newItemConditions = getSpeedAndOntOfConfigConditions(newItem);

    let found = items.find((item) => {
      const itemConditions = getSpeedAndOntOfConfigConditions(item);
      return itemConditions.some(v => newItemConditions.includes(v));
    });
    if (found) {
      return {
        error: 1,
        message: `Condition already used in configuration item ID ${found.id}.`,
        item: found
      };
    }
    else {
      return { error: 0 };
    }
  }

  useEffect(() => {
    getAll();
  }, [])

  function refreshItems() {
    getAll();
  };

  async function getAll() {
    let r = await getAllOntSpeedTestConfigs();
    r = r.map(item => {
      item.key = `stConfig-${item.id}`;
      item._key = item.key;
      return item;
    });
    if (r.length) {
      r.sort((a, b) => a.id - b.id);
      setItems(r);
      setNewItemsCount(r[r.length - 1].id + 1);
    }
  }

  function renderItems() {
    if (items.length) {
      return items.map((item, idx) => <OntSpeedTestConfigItem 
        id={item.id} speedProfiles={item.speedProfiles} ontModels={item.ontModels}
        dlRetryTimes={item.dlRetryTimes} dlSession={item.dlSession} dlFilename={item.dlFilename}
        dlPort={item.dlPort} ulRetryTimes={item.ulRetryTimes} ulSession={item.ulSession}
        ulFilename={item.ulFilename} ulPort={item.ulPort} ulCore={item.ulCore}
        removeItem={removeItem} _key={item.key} key={item.key} validateNewItem={validateNewItem}
        refreshItems={refreshItems}
      />)
    }
    else {
      return <Card>
        <section>
          <p>No items found.</p>
        </section>
        </Card>
    }
    
  }

  return <Container>
      <Row className="mb-5">
        <Col style={{textAlign: 'right'}} ><Button variant="info" onClick={addNewItem}>Add New Item</Button></Col>
      </Row>
      {renderItems()}
  </Container>
}