import React, { useRef, useEffect, useState } from "react";

import Row from 'react-bootstrap/Row';
import { Alert, Container } from "react-bootstrap";
import SparkSearch from "../components/spark/spark-search";
import OntDetails from "../components/spark/ont-details";
import SubscriberDetails from "../components/spark/subscriber-details";

export default function Spark({ }) {
  const [ont, setOnt] = useState(null);
  const [subscriber, setSubscriber] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  let ontDetails
  let subscriberDetails;
  let loadingComponent;
  let ontData;

  if (loading) {
    loadingComponent = <h2>Loading...</h2>;
  }
  else {
    if (ont || (subscriber && subscriber.subscriberInfo.ont)) {
      ontData = ont || subscriber.subscriberInfo.ont;
      ontDetails = <Row>
        <OntDetails ontDetails={ ont || subscriber.subscriberInfo.ont } />
      </Row>;
    }
    if (subscriber && subscriber.subscriberInfo.subscriber) {
      subscriberDetails = <Row>
        <SubscriberDetails subscriberDetails={subscriber.subscriberInfo.subscriber} />
      </Row>;
    }
  }

  let displayError;
  if (error) {
    displayError = <Alert variant="danger">
      <Alert.Heading>Error</Alert.Heading>
      <p>{error}</p>
    </Alert>;
  }

  return (
    <div className="spark">
      <h2>SPARK</h2>
      <Container>
        <Row>
          <SparkSearch setOnt={setOnt} setSubscriber={setSubscriber} setError={setError} setLoading={setLoading} />
        </Row>
        {loadingComponent}
        {displayError}
        {ontDetails}
        {subscriberDetails}
      </Container>
    </div>
  );
}
