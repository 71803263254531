import Card from '../card/Card';
import ReactApexChart from 'react-apexcharts';

export default function FibertestHistoryChart ({ data }) {
  console.log('Starting chart...');

  let counts = data.counts;
  let codes = data.codes;


  let series = [];
  let categories = [];
  let dayData = {};
  for(let year in counts)
    {
      for(let month in counts[year])
        {
          for(let day in counts[year][month])
            {
              let category = `${year}/${month}/${day}`
              categories.push(category);
              if(!dayData[category])
                {dayData[category] = {};}
              for(let resultCode in counts[year][month][day])
                {
                  if(!dayData[category][resultCode])
                    {dayData[category][resultCode] = 0}
                  dayData[category][resultCode] += counts[year][month][day][resultCode];
                }
              //Ensure all codes are present in the data set
              for(let code of codes)
                {
                  if(!Object.keys(dayData[category]).includes(code))
                    {dayData[category][code] = 0;}
                }
            }
        }
    }
  
  let seriesData = {};
  for(let category of categories)
    {
      console.log(`Processing date ${category}...`);
      for(let resultCode of Object.keys(dayData[category]).sort())
        {
          if(!seriesData[resultCode])
            {seriesData[resultCode] = [];}
          seriesData[resultCode].push(dayData[category][resultCode]);
        }
    }

    for(let resultCode in seriesData)
      {
        series.push(
          {
            name: resultCode,
            data: seriesData[resultCode]
          })
      }
  
  let chartOptions = {
    chart: {
      stacked: true,
      zoom: {
        enabled: false
      },
      background: 'transparent'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: '',
      align: 'left'
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      min: 0,
      forceNiceScale: true
    },
    theme: {
      mode: 'dark'
    }
  };
  

  return(
    <Card>
      <header>Results</header>
      <section>
        <ReactApexChart options={chartOptions} series={series} type="bar" height={258}></ReactApexChart>
      </section>
    </Card>
  )
}
