import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

export default function Recommendation({title, description, image, imageUrl, keyProp, deleteRecommendation, updateRecommendation}) {
  const [t, setTitle] = useState(title);
  const [d, setDescription] = useState(description);
  const [i, setImage] = useState(image);

  useEffect(() => {
    updateRecommendation(keyProp, t, d, i);
  }, [t, d, i]);

  async function updateImage(file) {
    let imageBase4 = await getBase64(file);
    setImage(imageBase4);
  }

  function getBase64(file) {
    let reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onload = function () {
          resolve(reader.result)
      };
      reader.onerror = function (error) {
          console.log('Error: ', error);
          reject(error);
      };
    });
  }

  return <Row className="mb-4">
    <Col md={3}>
      <Form.Group>
        <Form.Label>Title</Form.Label>
        <Form.Control type="text" value={t} onChange={e => {setTitle(e.target.value)}}/>
      </Form.Group>
    </Col>
    <Col md={4}>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control type="text" value={d} onChange={e => {setDescription(e.target.value)}}/>
      </Form.Group>
    </Col>
    <Col md={4}>
      <Form.Group>
        <Form.Label>Image</Form.Label>
        <Form.Control type="file" onChange={e => updateImage(e.target.files[0])} />
        <img src={imageUrl || i} />
      </Form.Group>
    </Col>
    <Col md={1}>
      <Form.Group>
        <Button variant="danger" onClick={deleteRecommendation.bind(null, keyProp)} className="mt-4">Delete</Button>
      </Form.Group>
    </Col>
  </Row>
}
