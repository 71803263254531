import React from "react";

const isClickedInside = (e, element) => {
  let node = e.target;
  while (node) {
    if (node === element) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

const PointPopup = ({ distance, ontRxSignalLevel, value, position, onClose, data }) => {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const onClick = e => {
      if (!isClickedInside(e, containerRef.current)) {
        onClose();
      }
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className='heatmap-popup'
      style={{
        top: position.y + 50 + "px",
        left: position.x + 50 + "px",
      }}
    >
      <div>
        <p><span className='label'>{data.xLabel}:</span>{data.xValue}</p>
        <p><span className='label'>{data.yLabel}:</span>{data.yValue}</p>
        <p><span className='label'>Quantity:</span>{value}</p>
      </div>
    </div>
  );
};

export default PointPopup;
