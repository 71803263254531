
import { useState, useEffect } from "react";
import { Card } from 'react-bootstrap';

import { getVQCStatus } from "../../utils/vqc.js";


export default function VqcCard({token}) {
	const [vqcStatus, setVqcStatus] = useState({});

	function renderedItems() {
		let cardItems = [];

		for (var key in vqcStatus) {
			cardItems.push(<Card.Text>{key} : {vqcStatus[key]}</Card.Text>) 
		}
	 
		return cardItems;
	}

	const _getVqcStatus = async () => {
		let res = await getVQCStatus();
		
		setVqcStatus(res);
	}

	useEffect(() => {
		_getVqcStatus();
	}, []);

	return (
		<div>
			<Card className="mb-2" bg="secondary" style={{ width: '18rem' }}>
				<Card.Header>VQC STATUS</Card.Header>
					<Card.Body>
						{renderedItems()}								             
					</Card.Body>
			</Card>
		</div>

	);
}
