import React, { useRef, useEffect, useState } from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import axios from "axios";

import GetDataFromDeviceForm from './get-data-from-device';
import PublishToTopicForm from "./publish-to-topic";
import TopicMonitor from "./topic-monitor";
import TR181Form from "./tr181-from-device";
import TopicMonitorWS from "./topic-monitor-ws";
import CPUMemoryMonitor from "./cpu-memory-monitor";

export default function MQTTControlServer({ }) {
  return (
    <div className="mqtt-control-server">
      <h2>MQTT Control Server</h2>

      <Tab.Container id="mqtt-control-server" defaultActiveKey="tr181">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="tr181">TR181</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="diags">Get Diagnostic Parameter From Device</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="publish">Publish to a Topic</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="monitor">Topic Monitor</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="cpuMemMonitor">CPU/Memory Monitor</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="monitor-ws">Topic Monitor WS</Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="tr181">
                <TR181Form />
              </Tab.Pane>
              <Tab.Pane eventKey="diags">
                <GetDataFromDeviceForm />
              </Tab.Pane>
              <Tab.Pane eventKey="publish">
                <PublishToTopicForm />
              </Tab.Pane>
              <Tab.Pane eventKey="monitor">
                <TopicMonitor />
              </Tab.Pane>
              <Tab.Pane eventKey="cpuMemMonitor">
                <CPUMemoryMonitor />
              </Tab.Pane>
              <Tab.Pane eventKey="monitor-ws">
                <TopicMonitorWS />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
