import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { EventType, InteractionRequiredAuthError } from '@azure/msal-browser';

import React, { useRef, useEffect, useState } from "react";
import logo from './logo.png';
import './App.scss';
import Heatmap from './components/heatmap/heatmap';
import { Heatmap2 } from './components/heatmap2/heatmap2';
import MapWrapper from './components/googlemap/googlemap';
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";
import { Container, Nav, NavDropdown, Navbar} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Home from './pages/home';
import Page404 from './pages/404';
import MQTTControlServer from './components/mqtt-control-server';
import { FtrHeatmap } from './components/ftrHeatmap/ftrHeatmap';
import Login from './components/login/login';
import useToken from "./hooks/useToken";
import SparkMonitor from './components/spark-monitor';
import { OpticsByState } from "./components/opticsByState/heatmapByState";
import { apiAuth, getAccessToken } from './utils/auth';
import LoginLocalhost from './components/login/login-localhost';
import NotAllowed from './pages/503';
import CMS from './pages/cms';
import Spark from './pages/spark';
import SparkAPI from './pages/spark-apis';
import Outage from './pages/outage';
import Fibertest from './pages/fibertest';
import { loginRequest } from './msAuthConfig';
import Smith from './pages/smith';


function App({ msalInstance }) {
  const [authResponse, setAuthResponse] = useState();
  const navigate = useNavigate();
  const { token, setToken, isTokenValid } = useToken();
  let activeAccount;

  console.log('msalInstance: ', msalInstance);
  if (msalInstance) {
    activeAccount = msalInstance.getActiveAccount();
    if (activeAccount) {
      let msToken = activeAccount.idTokenClaims;
      msToken.accessToken = activeAccount.accessToken;
      getApiToken(msToken.accessToken);
    }
  }

  async function getApiToken(msToken) {
    try {
      const token = await getAccessToken();
      let apiAuthResp = await apiAuth(token);
      setToken(apiAuthResp.userToken);
    }
    catch(error) {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(loginRequest);
      }
      console.log(error);
    }
  }
  
  useEffect(() => {
    window.addEventListener('focus', () => {
      if (!isTokenValid()){
        setToken(null);
        navigate('/', { replace: true });
        if (activeAccount) {
          msalInstance.logoutRedirect({
            account: activeAccount,
          });
        }
      }
    });

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(async (event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
        setAuthResponse(event.payload);
        let msToken = event.payload.account.idTokenClaims;
        msToken.accessToken = event.payload.accessToken;
        msToken.idToken = event.payload.idToken;
        await getApiToken(msToken);
      }
    });
  }, []);

  // if (window.location.host === 'localhost:3000'){
  //   return localhostApp(token, setToken);
  // }

  return (
    <MsalProvider instance={msalInstance}>
      <div className="app">
        <header className="header">
          <img src={logo} className="app-logo" alt="logo" />
          <h1>PSI Console</h1>
        </header>
        <AuthenticatedTemplate>
          {!token && <NotAllowed />}
          {token && <Routes>
            <Route path="/" element={<Layout setToken={setToken} msalInstance={msalInstance} />}>
                <Route index element={<Home />} />
                <Route path="heatmap" element={<Heatmap />} />
                {/* <Route path='ftrHeatmap' element={<FtrHeatmap token={token} />} /> */}
                <Route path="heatmap2" element={<Heatmap2 />} />
                <Route path="mqtt-control-server" element={<MQTTControlServer />} />
                <Route path="spark-monitor" element={<SparkMonitor />} />
                <Route path="optics-state" element={<OpticsByState />} />
                <Route path="spark-cms" element={<CMS />} />
                <Route path="spark" element={<Spark />} />
                <Route path="spark-api-monitor" element={<SparkAPI />} />
                <Route path="outage" element={<Outage />} />
                <Route path="fibertest" element={<Fibertest />} />
                <Route path="smith" element={<Smith />} />

                {/* Using path="*"" means "match anything", so this route
                      acts like a catch-all for URLs that we don't have explicit
                      routes for. */}
                <Route path="*" element={<Page404 />} />
            </Route>
          </Routes>}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div>
            <Container className='pt-2 mt-5 w-50 mx-auto'>
            <Routes>
              <Route path="*" element={<Login setToken={setToken} />} />
            </Routes>
          </Container>
          <Footer />
        </div>
        </UnauthenticatedTemplate>
      </div>
    </MsalProvider>
  );
}

function localhostApp(token, setToken) {
  let routes = <Routes>
  <Route path="/" element={<Layout setToken={setToken} />}>
    <Route index element={<Home token={token}/>} />
    <Route path="heatmap" element={<Heatmap token={token} />} />
    <Route path="heatmap2" element={<Heatmap2 token={token} />} />
    <Route path="mqtt-control-server" element={<MQTTControlServer token={token} />} />
    <Route path="spark-monitor" element={<SparkMonitor token={token} />} />
    <Route path="optics-state" element={<OpticsByState token={token} />} />
    <Route path="spark-cms" element={<CMS token={token} />} />
    <Route path="spark" element={<Spark token={token} />} />
    <Route path="spark-api-monitor" element={<SparkAPI token={token} />} />
    <Route path="*" element={<Page404 />} />
    </Route>
  </Routes>;

  if (!token) {
    routes = <div>
            <Container className='pt-2 mt-5 w-50 mx-auto'>
            <Routes>
              <Route path="*" element={<LoginLocalhost setToken={setToken} />} />
            </Routes>
          </Container>
          <Footer />
        </div>
  }

  return (
    <div className="app">
      <header className="header">
        <img src={logo} className="app-logo" alt="logo" />
        <h1>PSI Console Localhost</h1>
      </header>
      {routes}
      </div>
  );
}

function Layout({ setToken, msalInstance }) {
  const navigate = useNavigate();

  const logout = () => {
    setToken(null);
    navigate('/', { replace: true });
    msalInstance.logoutRedirect({
      account: msalInstance.getActiveAccount(),
    });
  };

  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <NavDropdown title="ONT Optics">
                <NavDropdown.Item href="/heatmap">Heatmap</NavDropdown.Item>
                <NavDropdown.Item href="/heatmap2">Heatmap 2.0</NavDropdown.Item>
                <NavDropdown.Item href="/optics-state">Optics Data by State</NavDropdown.Item>
              </NavDropdown>
              <LinkContainer to="/mqtt-control-server">
                <Nav.Link>MQTT Control Server</Nav.Link>
              </LinkContainer>
              <NavDropdown title="SPARK">
                <NavDropdown.Item href="/spark">SPARK</NavDropdown.Item>
                <NavDropdown.Item href="/spark-monitor">SPARK Monitor</NavDropdown.Item>
                <NavDropdown.Item href="/spark-cms">SPARK CMS</NavDropdown.Item>
                <NavDropdown.Item href="/spark-api-monitor">SPARK Support</NavDropdown.Item>
              </NavDropdown>
              <LinkContainer to="/outage">
                <Nav.Link>Outage</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/fibertest">
                <Nav.Link>Fibertest</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/smith">
                <Nav.Link>Agent Smith</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav>
              <Nav.Link onClick={ev => {ev.preventDefault(); logout();}}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className='pt-2 mt-5'>
          <Outlet />
      </Container>
      <Footer />
    </div>
  );
}

function Footer() {
  return (
    <footer>
    <p>Environment: {process.env.NODE_ENV}</p>
    <p>Version: {process.env.REACT_APP_VERSION}</p>
  </footer>
  );
}

export default App;
