import { useState } from "react";
import TerminalOutageMap from "../components/outage/terminal-outage-map";
import Card from "../components/card/Card";
import { Col, Container, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

export default function Outage () {
  let defaultDate = new Date();
  const [endDate, setEndDate] = useState(defaultDate);
  defaultDate = new Date();
  defaultDate.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState(defaultDate);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() > selectedDate.getTime();
  };

  const filterEndTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() > selectedDate.getTime() && selectedDate.getTime() > startDate.getTime();
  };

  return <div className="outage">
    <h2>Outage monitor</h2>
    <Card className={'form-options'}>
      <header>Options</header>
      <section style={{zIndex: 2}}>
        <Form>
          <Container>
            <Row>
              <Form.Group className="mb-3" as={Col} md="4">
                <Form.Label>Start Date</Form.Label>
                <ReactDatePicker 
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  filterTime={filterPassedTime}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                >
                </ReactDatePicker>
              </Form.Group>
              <Form.Group className="mb-3" as={Col} md="4">
                <Form.Label>End Date</Form.Label>
                <ReactDatePicker 
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  filterTime={filterEndTime}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  electsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                >
                </ReactDatePicker>
              </Form.Group>
            </Row>
          </Container>
        </Form>
      </section>
    </Card>
    <TerminalOutageMap startDate={startDate} endDate={endDate} />
  </div>
}

