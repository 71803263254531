import { useEffect, useState } from 'react';
import Card from '../card/Card';
import { getSpeedTestResults } from '../../utils/psi-rg-apis';

export default function SpeedTestResults ({}) {
	const [testResults, setTestResults] = useState([]);
	const [resultHistory, setHistory] = useState({});//to be used later on for clickable ONT history

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		let data = await getSpeedTestResults('all');
		let histGroup = {};
		
		data.forEach((item)=>{
			if (!histGroup[item.serialNumber]) {
	
				histGroup[item.serialNumber] = [];
			};
			histGroup[item.serialNumber].push(item);

		});

		//Create the test REsult list
		//go through the list and get the latest of each
		var tempTestResults = []; //latest test results for all ONTs 
		for (var ontsn in histGroup) {
			let history = histGroup[ontsn];
			history.sort((a, b) => b.timeStamp - a.timeStamp);
			tempTestResults.push(history[0]);		 
		}

		//sort this new list testresults from latest at top
		tempTestResults.sort((a, b) => b.timeStamp - a.timeStamp);

		console.log('SpeedTestResults ', JSON.stringify(tempTestResults));

		//create a function to get results of specific ont and put in order

		setTestResults(tempTestResults);
		setHistory(histGroup);
	}

	const getTableRows = () => {
		let rows = testResults.map((test, i) => {

			let localTime = (new Date(test.timeStamp)).toLocaleString();

			return <tr key={`test-results-key-${test.serialNumber}`}>
				<td>{test.serialNumber}</td>
				<td>{localTime}</td>
				<td>{test.downloadRate || ''}</td>
				<td>{test.uploadRate || ''}</td>
				<td>{test.downloadLatency || ''}</td>
				<td>{test.provisionSpeed || ''}</td>
			</tr>;
		});
		return rows;
	}


//TODO: make the serial number clickable. and launch pop up and display history
	let content = <h3>No Speed Test Results.</h3>;
	if (testResults.length) {
		content = <table className='table table-dark table-striped table-hover'>
			<thead>
				<tr>
					<td>serialNumber</td>  
					<td>Date</td>  
					<td>Download</td>  
					<td>Upload</td>
					<td>Download Latency</td>
					<td>Provisioned Speed</td>
				</tr>
			</thead>
			<tbody>
				{getTableRows()}
			</tbody>
		</table>;
	}
	
	return (
		
		<Card className={'top-10 olt-sessions'}>
			<header>Speed Test Results</header>
			<section>
				{ content }
			</section>
		</Card>
	)
}

