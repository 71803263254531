import { Container, Stack, Row, Col, Button, Card, InputGroup, Form, Table } from "react-bootstrap";
import { useState, useEffect } from "react";

import { getAccessToken } from "../../../utils/auth.js";

import axios from 'axios';

const _localRouterList = require('./configs/rgDeviceTable.json');

const rgUrl = 'https://rg.api.fybrlabs.frontier.com';
const rgModelsPath = '/Prod/v1/config/routers';

export default function RGManagement ({}) {  
    //data for the thresholds  
    
    const title = "Router Management";

    const [renderIteration, setRender] = useState({iteration: 0});
    const [routers, setRouters] = useState([]);
    const [editMode, setEditMode] = useState('Edit');
    const [editItem, setItemInEdit] = useState(''); //model number changed on ui
    const [changedItem, setChangedItem] = useState({}); //item to be saved
    const [priorities, setPriority] = useState([]); //[{modelNum:xxx, priority:xxx}]
    const [priorityChanged, setPrioChange] = useState({}); 
    

    const renderBody = () => {
        let body = '';

        console.log('render Body');
        body = routers.map(router => {
            
            let priority = router?.priority < 4 && router.priority || ""; //missing on Data.  
            let modelNumber = router.modelNumber || "";
            let modelImg = router.imageUrl || ""; //grabbed separately, find image on s3 bucket
            let dataSpeed = router.dataSpeed || "";
            let video = router.video ? "true" : "false";
            let voice = router.voicePort ? "true" : "false";
            let vendor = router.vendor || "";
            let network = Array.isArray(router.network) && router.network.join() || "";
            let extenderModel = router.extenderModel; 
            let extImageUrl = router.extImageUrl || ""; //grabbed separately, find image on s3 bucket
            let wanConnections = router.wanConnections || "false";            
        
            //default mode
            let editConfirmBtn = 'Edit';
            let delCancelBtn = 'Delete';
            let saveColorTheme = 'warning';
            let delColorTheme = 'danger';
            let showButtons = true;
                   
            switch(editMode){
                case "Save"://editmode            
                    editConfirmBtn = 'Save';
                    delCancelBtn = 'Cancel';
                    saveColorTheme = 'success';
                    delColorTheme = 'danger';  
                    if (editItem != modelNumber) { 
                        showButtons = false;
                    }       
                    break;                    
                case "Proceed": //deletemode
                    editConfirmBtn = 'Proceed';
                    delCancelBtn = 'Cancel';
                    saveColorTheme = 'danger';
                    delColorTheme = 'primary';                     
                    if (editItem != modelNumber) { 
                        showButtons = false;
                    }
                    break; 
                case "Edit":                                            
                default:                     
            }

            if (editItem == modelNumber) {
                priority = (<select id='rgpriority' defaultValue={priority} onChange={handleChange}>
                    <option value="4">remove</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>)
                dataSpeed = <input id='rgdataspeed' type="text" size="8" placeholder={dataSpeed} onChange={handleChange}/>
                video = (<select id='rgvideo' defaultValue={video} onChange={handleChange}>
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </select>)
                voice = (<select id='rgvoice' defaultValue={video} onChange={handleChange}>
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </select>)
                vendor = <input id='rgvendor' type="text" size="8" placeholder={vendor} onChange={handleChange}/>
                network = <input id='rgnetwork' type="text" size="12" placeholder={network} onChange={handleChange}/>
                extenderModel = <input id='rgextenderModel'type="text" size="8" placeholder={extenderModel} onChange={handleChange}/>
                wanConnections = (<select id='rgwanConnections' defaultValue={wanConnections} onChange={handleChange}>
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </select>)                            
            }
            
            return (<tr>
                <td>
                    <div className="rgmgt-item">
                        {priority}
                    </div>
                </td>  
                <td>
                    <div className="rgmgt-item withimg">
                        <div>{modelNumber}</div>
                        <img src={modelImg} />
                    </div>
                </td>
                <td>
                <div className="rgmgt-item">{dataSpeed}</div></td>
                <td>
                <div className="rgmgt-item">{video}</div></td> 
                <td >
                <div className="rgmgt-item">
                    {voice}</div>
                </td> 
                <td>
                <div className="rgmgt-item">{vendor}</div></td> 
                <td>
                <div className="rgmgt-item">{network}</div></td>                          
                <td>
                <div className="rgmgt-item withimg">
                    <div>{extenderModel}</div>
                    <img src={extImageUrl}/></div>
                </td>          
                <td>
                <div className="rgmgt-item">{wanConnections}</div></td>    
                <td>
                {showButtons && <div className="rgmgt-item rgmt-table-btns">
                        <Button id={modelNumber} onClick={toggleEditBtn} variant={saveColorTheme}>{editConfirmBtn}</Button>
                        <Button id={modelNumber} onClick={toggleEditBtn} variant={delColorTheme}>{delCancelBtn}</Button>
                    </div>}
                </td> 
            </tr>)
        });

        return body;  
    }

     const toggleEditBtn = (e) => {
        
        console.log('button edit',e.target);

        let newMode = 'Cancel';

        switch(e.target.innerText){
            case "Edit":                 
                setItemInEdit(e.target.id);
                newMode = 'Save';
                // changes items to input
                break;
            case "Delete": 
                // changes button       
                setItemInEdit(e.target.id);         
                newMode = 'Proceed';       
                break;
            case "Save":          
                saveChanges();
                setItemInEdit('');
                newMode = 'Edit';                    
                break;
            case "Proceed": 
                //call delete fn
                setItemInEdit('');
                newMode = 'Edit';       
                break;
            default:
                setItemInEdit('');
                setPrioChange({});    
                //changes all back to read only. 
                 
        }

        setEditMode(newMode);    

    }

    const handleChange = (e) => {        
        let key = (e.target.id).replace('rg','');        
        let current = changedItem;
        current[key] = e.target.value;
        const updatedItem = current;

        //find the current priority number on list. 
        if (key === "priority") {            
            updatedItem.modelNumber = editItem;                        
            setPrioChange(updatedItem);                             
        }
        setChangedItem(updatedItem);
        console.log('changedItem ', changedItem);
        console.log('changedItem ', editItem);
    }

    //only top 3 priority will show. 
    const _shiftPriorities = () => {
        let priorityItems = priorities;
        let newPriorityItem = true;

        for (var i in priorityItems) {
            if (priorityItems[i].modelNumber === priorityChanged.modelNumber) {
                newPriorityItem = false;
                //found similar                         
                priorityItems.splice(i, 1);//remove the similar item
                priorityItems.splice(priorityChanged.priority - 1, 0, priorityChanged);//insert new priority rank            
                break;
            }   
        }   
        if (newPriorityItem) {
            
            let priorityToChange = priorityItems.splice(priorityChanged.priority - 1, 1);
            console.log('single item to be changed  ', priorityItems);
            priorityToChange[0].priority = 4;
            priorityItems = priorityToChange;
            debugger;
        }
        else {        
            console.log('inserted priority list ', priorityItems);                    
            //then reassign priority for each again. 
            let prioCounter = 1;
            priorityItems.forEach((router)=>{
                router.priority = prioCounter;
                prioCounter++;
            })
            console.log('new priority priority list ', priorityItems);
            
            //remove again the edited item to prevent double request. 
            priorityItems.splice((priorityChanged.priority - 1), 1);
        }

        return priorityItems;     
    }

    const saveChanges = async() => {
        let token = await getAccessToken();   

        let body = changedItem;
        let modelName = editItem;
        let promises = [];
        
         try {
            //TODO: Handle cross request
            // let resp = await axios.put(`${rgUrl}${rgModelsPath}/${modelName}`, body, {headers: {'auth-token': token }});    
            console.log(`first ${rgUrl}${rgModelsPath}/${modelName}`);

            promises.push(axios.put(`${rgUrl}${rgModelsPath}/${modelName}`, body, {headers: {'auth-token': token }}))
            
            if (Object.keys(priorityChanged).length > 0) {
                let priorityItems = _shiftPriorities();               
                
                priorityItems.forEach((router)=> {
                    body = router;
                    modelName = router.modelNumber;
                    console.log('router', router);
                    console.log(`second ${rgUrl}${rgModelsPath}/${router.modelNumber}`);
    
                    promises.push(axios.put(`${rgUrl}${rgModelsPath}/${modelName}`, body, {headers: {'auth-token': token }}))
                });
                setPrioChange({});
            }

            await Promise.allSettled(promises).then(
                (results) => results.forEach((result) => console.log('update Router resp', result.status))
            );
            
            await getRouters();
            console.log('newIteration', renderIteration);
            let newIteration = {iteration: Math.random()}
            setRender(newIteration);
            // console.log('updateRouter resp', resp);
        } catch(e) {
            console.error('updateRouter: ', e);
        }
    }

    //Gets Data Before Mount
    useEffect(() => {
		getRouters();
    }, []);

    const getRouters = async() => {
        let routers = [];
        console.log('getRouters Start');
        let token = await getAccessToken();            
        
        let routerList = _localRouterList;
        let resp;

        try {
            resp = await axios.get(`${rgUrl}${rgModelsPath}`, {headers: {'auth-token': token }});    
            
        } catch(e) {
            console.error('getRouters: ', e);
        }
        // TODO: create flag if its local data.
        console.log('getRouters url response', resp);
        if (resp?.data) {
            routerList = resp.data;
        }        
        
        routerList = routerList.preferred.concat(routerList.nonPreferred);
        console.log('getRouters merge preference lists into one');
        
        //Sort by Model name alphabetically then shift the priority to top. 
        routers = routerList.sort((a, b)=>{
            
            if (a.priority !== 0 && !a.priority) {
                a.priority = 4;
            }

            if (b.priority !== 0 && !b.priority) {
                b.priority = 4;
            }

            if (a.priority < b.priority) {
                return -1;
            }
            else if (a.priority > b.priority) {
                return 1;
            }

            if (a.modelName < b.modelName) {
                return -1;
            }
            else if (a.modelName > b.modelName) {
                return 1;
            }

            return 0;
        });
        console.log('getRouters sorting alphabetically');

        let priorityItems = routers.filter((router) => {            
            return router.priority < 4;
        })

        console.log("priority items to be set on load", priorityItems);

        setPriority(priorityItems);
        

        const newrouters = [...routers];

        setRouters(newrouters);        
    }

    const addRouter = () => {
        //TODO: for new feature
    }

    //Missing: Wan Connections
    return <Container className="cms-rgmanage">
                <div key={renderIteration.iteration}></div>
                <p className="rgMgt-Title">Router Management</p>
            
                        <Table className="table table-dark table-striped table-hover">
                            <thead>
                                    <tr>
                                        <th><p >Priority</p></th>	
                                        <th><p className="headerItems">Router</p></th>
                                        <th><p className="headerItems">MAX Speed</p></th>
                                        <th><p className="headerItems">Video</p></th>
                                        <th><p className="headerItems">Voice</p></th>	
                                        <th><p className="headerItems">Vendor</p></th>							
                                        <th><p className="headerItems">Network</p></th>									                                        							
                                        <th><p className="headerItems">Extender</p></th>	
                                        <th><p className="headerItems">WAN CX</p></th>	
                                        <th><p className="headerItems"></p></th>																						
                                    </tr>
                            </thead>
                            <tbody>
                                {renderBody()}
                            </tbody>
                        </Table>
                

    </Container>;

}