import { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import Card from "../../card/Card";
import { putOntSpeedTestConfig } from "../../../utils/cms-apis";

export default function OntSpeedTestConfigItem (props) {
  const [validated, setValidated] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(props?.id || '');
  const [speedProfiles, setSpeedProfiles] = useState(props?.speedProfiles || []);
  const [ontModels, setOntModels] = useState(props?.ontModels || []);
  const [dlRetryTimes, setDlRetryTimes] = useState(props?.dlRetryTimes || 0);
  const [dlSession, setDlSession] = useState(props?.dlSession || 8);
  const [dlFilename, setDlFilename] = useState(props?.dlFilename || '/speedtest/random7000x7000.jpg');
  const [dlPort, setDlPort] = useState(props?.dlPort || 8080);
  const [ulRetryTimes, setUlRetryTimes] = useState(props?.ulRetryTimes || 0);
  const [ulSession, setUlSession] = useState(props?.ulSession || 8);
  const [ulFilename, setUlFilename] = useState(props?.ulFilename || '/speedtest/random7000x7000.jpg');
  const [ulPort, setUlPort] = useState(props?.ulPort || 8080);
  const [ulCore, setUlCore] = useState(props?.ulCore || 15);
  const runTimeLimit = 40 + dlRetryTimes * 10 + ulRetryTimes * 10;
  const [error, setError] = useState('');

  const speedProfilesOptions = ['0.5G', '1G', '2G', '5G', '7G', '10G', '25G', '50G', '100G'];
  const ontModelsOptions = ['FOX222', 'FRX523', 'FRG222'];

  async function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    // Reset checkboxes to validate them again.
    for (let i = 0; i < form.elements.length; i++) {
      const el = form.elements[i];
      if (el.type === 'checkbox') {
        el.setCustomValidity('');
      }
    }

    if (form.checkValidity()) {
      let item = {
        id: parseInt(id),
        speedProfiles, ontModels,
        dlRetryTimes: parseInt(dlRetryTimes),
        dlSession,
        dlFilename,
        dlPort: parseInt(dlPort),
        ulRetryTimes: parseInt(ulRetryTimes),
        ulSession,
        ulFilename,
        ulPort: parseInt(ulPort),
        ulCore,
        runTimeLimit
      };
      let validation = props.validateNewItem && props.validateNewItem(item);
      if (!validation.error) {
        await putOntSpeedTestConfig(item);
        setError('');
        if (props.refreshItems) {
          props.refreshItems();
        }
      }
      else {
        for (let i = 0; i < form.elements.length; i++) {
          const el = form.elements[i];
          if (el.type === 'checkbox') {
            el.setCustomValidity('Invalid');
          }
        }
        setError(validation.message);
      }
    }
    
    setValidated(true);
  }

  function handleCheckbox(e) {
    let values;
    let setFunction;

    if (e.target.dataset.type === 'speedProfile') {
      values = speedProfiles;
      setFunction = setSpeedProfiles;
    }
    else if (e.target.dataset.type === 'ontModel') {
      values = ontModels;
      setFunction = setOntModels;
    }
    
    if (e.target.checked && !values.includes(e.target.value)) {
      values.push(e.target.value);
      setFunction(values);
    }
    else if (!e.target.checked && values.includes(e.target.value)) {
      values.splice(values.indexOf(e.target.value), 1);
      setFunction(values);
    }
  }

  return <Card className={'OntSpeedTestConfigItem'}>
    <section>

      {error && <Alert variant="danger">{error}</Alert>}
    <Form onSubmit={onSubmit} validated={validated} noValidate >
      <Row>
        <Col md={5}>
          <Form.Group className="form-group" controlId="validationCustom01">
            <Form.Label>Configuration ID</Form.Label>
            <Form.Control type="number" value={id} onChange={e => {setId(e.target.value)}} required />
            <Form.Control.Feedback type="invalid">Please provide a Configuration ID.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Form.Group className="form-group">
            <Form.Label>Speed Profiles</Form.Label>
            {speedProfilesOptions.map(item => <Form.Check key={`speedProfile-${item}`} type="checkbox" data-type='speedProfile' label={item} value={item} defaultChecked={speedProfiles.includes(item)} onChange={handleCheckbox} />)}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group className="form-group">
            <Form.Label>ONT Models</Form.Label>
            {ontModelsOptions.map(item => <Form.Check key={`ontModels-${item}`} type="checkbox" data-type='ontModel' label={item} value={item} defaultChecked={ontModels.includes(item)} onChange={handleCheckbox} />)}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Form.Group className="form-group">
            <Form.Label>Download retry times</Form.Label>
            <Form.Control type="number" value={dlRetryTimes} onChange={e => {setDlRetryTimes(e.target.value)}} min={0} max={1}/>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Download session</Form.Label>
            <Form.Control type="number" value={dlSession} onChange={e => {setDlSession(e.target.value)}} min={1} max={8}/>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Download filename</Form.Label>
            <Form.Control type="text" value={dlFilename} onChange={e => {setDlFilename(e.target.value)}}/>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Download port</Form.Label>
            <Form.Control type="number" value={dlPort} onChange={e => {setDlPort(e.target.value)}}/>
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group className="form-group">
            <Form.Label>Upload retry times</Form.Label>
            <Form.Control type="number" value={ulRetryTimes} onChange={e => {setUlRetryTimes(e.target.value)}}  min={0} max={1}/>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Upload session</Form.Label>
            <Form.Control type="number" value={ulSession} onChange={e => {setUlSession(e.target.value)}} min={1} max={8}/>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Upload filename</Form.Label>
            <Form.Control type="text" value={ulFilename} onChange={e => {setUlFilename(e.target.value)}}/>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Upload port</Form.Label>
            <Form.Control type="number" value={ulPort} onChange={e => {setUlPort(e.target.value)}}/>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Upload core</Form.Label>
            <Form.Control type="number" value={ulCore} onChange={e => {setUlCore(e.target.value)}}/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={2}><Button type="submit" variant="primary">Save</Button></Col>
        <Col md={2}><Button variant="danger" onClick={props.removeItem.bind(null, props._key)}>Delete</Button></Col>
      </Row>
    </Form>
    </section>
  </Card>;

}