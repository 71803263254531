import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import axios from "axios";
import { getAccessToken } from "../../utils/auth";

const apiEndpoint = 'https://mqtt.api.fybrlabs.frontier.com/Prod/v1';
// const apiEndpoint = 'http://localhost:3000/v1';

async function handleFormSubmission(topic, command, setResp, event) {
  event.preventDefault();
  console.log(topic, command);
  command = command.replace(/[\r\n]/gm, '');
  
  if (command === '') {
    setResp('');
    return;
  }

  setResp('Loading...');

  let r;

  let requestCommand;

  try {
    requestCommand = JSON.parse(command);
  } catch (e) {
    requestCommand = command;
  }

  try {
    const authToken = await getAccessToken();
    r = await axios.post(`${apiEndpoint}/publish`, {
      topic: topic,
      command: requestCommand
    }, {headers: {'auth-token': authToken}});
    console.log('response: ', r.data);
    setResp(r.data);
  } catch(e) {
    if (e.code === 'ECONNABORTED') {
      setResp({
        message: e.message,
        description: `Device ${topic} did not respond.`
      });
    } else {
      console.log('Error:', e)
      console.error(e.code);
      setResp(e);
    }
  }
}

export default function PublishToTopicForm({ }) {
  const [command, setCommand] = useState('0');
  const [topic, setTopic] = useState('');
  const [resp, setResp] = useState(null);

  let response;

  if (resp) {
    response = <div className="response mt-4"><h4>Response: </h4><pre>{JSON.stringify(resp, null, 2)}</pre></div>
  }
  else {
    response = false;
  }

  return (
    <div className="mqtt-form">
      <h3>Publish to a topic</h3>
      <Form onSubmit={handleFormSubmission.bind(null, topic, command, setResp)}>
        <Row className="mb-3">
          <Form.Group className="mb-3" as={Col} md="3">
            <Form.Label>Topic</Form.Label>
            <Form.Control type="text" placeholder="Enter Topic" value={topic} onChange={e => setTopic(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" as={Col} md="9">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={5} onChange={e => setCommand(e.target.value)} required />
          </Form.Group>
        </Row>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      {response}
    </div>
  )
}