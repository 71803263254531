import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { io } from "socket.io-client";

export default function TopicMonitor() {
  const [messages, setMessages] = useState([]);
  const [topic, setTopic] = useState('');
  const [listening, setListening] = useState(false);
  const socket = io("http://35.87.182.156:3002");

  useEffect(() => {
    return function cleanup() {
      socket.disconnect();
      console.log('Disconnecting from SocketIO server.');
    };
  }, []);

  const messagesList = messages.map(m => <pre className="mqtt-message" key={m.key}>{JSON.stringify(m.body, null, 2)}</pre>);

  function handleFormSubmission(event) {
    event.preventDefault();

    const onMessage = (m) => {
      let message = {
        key: `message-${Date.now()}`,
        body: m
      }
      setMessages(prev => [message, ...prev]);
    }

    if (listening) {
      socket.off('message', onMessage);
      setListening(false);
      socket.emit('unsubscribe', topic);
      return;
    }

    socket.emit('subscribe', topic);
    setListening(true);
    socket.on('message', onMessage);
  }

  const clearMessages = () => {
    setMessages([]);
  }

  let button;

  if (listening) {
    button = <Button variant="warning" type="cancel">Disconnect</Button>
  } else {
    button = <Button variant="primary" type="submit">Connect</Button>
  }

  return (
    <div className="mqtt-form">
      <h3>Topic Monitor</h3>
      <Form onSubmit={handleFormSubmission}>
        <Row className="mb-3">
          <Form.Group className="mb-3" as={Col} md="3">
            <Form.Label>Topic</Form.Label>
            <Form.Control type="text" placeholder="Enter Topic" value={topic} onChange={e => setTopic(e.target.value)} required />
          </Form.Group>
        </Row>
        <Row>
          <Col md="9">{button}</Col>
          <Col md="3"><Button variant="secondary" type="button" onClick={clearMessages}>Clear messages</Button></Col>
        </Row>
      </Form>
      <div className="mqtt-messages-list">
        {messagesList}
      </div>
    </div>
  )
}
